import React from "react";
import { useNavigate } from "react-router-dom";
import { useOffer } from "../../utils/Contexts/V2/OfferContext";

function AITextMarketingAIDescPrompt() {
  const navigate = useNavigate();
  const {
    setShowAITextMarketingPrompt,
    setShowAITextMarketingUserPrompt,
    setNewOffer,
  } = useOffer();

  return (
    <div className="relative w-screen h-screen  flex justify-center items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute h-full w-full bg-black/[.6]"
        onClick={() => setShowAITextMarketingPrompt(false)}
      />
      <div className="absolute z-20 flex flex-col justify-start items-center space-y-5 w-full px-4 pt-[1.7rem] pb-6 bg-white rounded-t-2xl md:rounded-2xl self-end md:self-center lg:max-w-screen-sm">
        <p className="text-base font-semibold">Choose text marketing style</p>
        <p className="text-base font-normal">
          Let AI create a quick, effective text for you, or write your own
        </p>
        <div className="flex flex-col space-y-3 w-full">
          <button
            type="button"
            className="w-full h-12 text-white text-base font-semibold rounded-xl bg-primary-50 thryve:text-base"
            onClick={() => setShowAITextMarketingUserPrompt(true)}
          >
            Use AI
          </button>
          <button
            type="button"
            className="w-full h-12 text-white text-base font-semibold rounded-xl bg-primary-50 thryve:text-base"
            onClick={() => {
              localStorage.removeItem("NEW_OFFER");
              setNewOffer({
                title: "",
                description: "",
                is_active: false,
                used_ai: false,
                add_link: true,
                select_all_contacts: false,
              });
              setShowAITextMarketingPrompt(false);
              navigate("/create-offers");
            }}
          >
            Use Own Content
          </button>
        </div>
      </div>
    </div>
  );
}

export default AITextMarketingAIDescPrompt;
