import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../components/layout/V2/Layout";
import Button from "../../../components/forms/Button";
import LabelledInput from "../../../components/forms/LabelledInput";
import LabelledTextarea from "../../../components/forms/LabelledTextarea";
import { useOffer } from "../../../utils/Contexts/V2/OfferContext";
import SociableHourBanner from "../../../components/sociable-hour-banner/sociable-hour-banner";
import useCoupons from "../../../utils/hooks/useCoupons";
import DatePickerInputV2 from "../../../components/forms/DatePickerInputV2";
import CouponsSwitchType from "../../../components/forms/coupon-switch";
import Image from "../../../assets/images/Image.svg";

export default function OfferDetails() {
  const {
    createNewOfferHandler,
    newOfferHandler,
    isCreatingOffer,
    showSociableHourBanner,
    setShowSociableHourBanner,
    showSociableHoursHandler,
    newOffer,
  } = useOffer();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    makeOnlineOffer,
    setMakeOnlineOffer,
    setValue,
    watch,
    persistentCouponDataHandler,
    isCreatingCoupon,
  } = useCoupons({});

  useEffect(() => {
    if (location.pathname === "/create-offers") {
      showSociableHoursHandler();
    }
  }, [location.pathname]);

  return (
    <Layout headerTitle="Create Text Marketing Campaign" header>
      <SociableHourBanner
        showBanner={showSociableHourBanner}
        setShowBanner={setShowSociableHourBanner}
      />
      <div className="relative w-full lg:max-w-screen-md h-screen scroll-smooth pt-20 flex flex-col items-center justify-between">
        <div className="px-4 flex flex-col justify-center items-center space-y-4 w-full pb-8">
          <div className="relative w-[15.56rem] h-1 rounded-lg bg-primary-20">
            <div className="w-[25%] bg-primary-50 h-1 rounded-lg" />
          </div>
          <p className="px-7 font-normal text-base font-sm text-center">
            Grab your customer&apos;s attention. Use an appealing title and
            description
          </p>
          <div className="w-full flex flex-row justify-start items-center space-x-2">
            <input
              type="checkbox"
              name="add_link"
              onChange={() => setMakeOnlineOffer((prevState) => !prevState)}
              checked={makeOnlineOffer}
              className="accent-primary-0"
            />
            <p>Also make this an online offer</p>
          </div>
          <div className="flex flex-col justify-start items-center space-y-6 w-full">
            <LabelledInput
              id="title"
              placeholder="Enter text Campaign title"
              label="Title"
              name="title"
              onChange={(e) => {
                newOfferHandler(e);
                setValue("title", e.target.value);
              }}
              value={newOffer?.title}
            />
            <LabelledTextarea
              label="Description"
              placeholder="Enter text campaign description"
              name="description"
              rules={{ required: true }}
              id="description"
              onChange={(e) => {
                newOfferHandler(e);
                setValue("description", e.target.value);
              }}
              value={newOffer?.description}
            />

            {makeOnlineOffer && (
              <>
                <div className="w-full flex flex-row justify-between items-start ">
                  <div className="w-[50%] lg:w-[80%]">
                    <LabelledInput
                      id="discount"
                      placeholder={
                        watch("discount_type") === "FLAT_AMOUNT"
                          ? "Enter an amount off"
                          : "Enter a discount off"
                      }
                      label={
                        watch("discount_type") === "FLAT_AMOUNT"
                          ? "Enter an amount off"
                          : "Enter a discount off"
                      }
                      name={
                        watch("discount_type") === "FLAT_AMOUNT"
                          ? "amount"
                          : "discount"
                      }
                      onChange={(e) =>
                        setValue(
                          watch("discount_type") === "FLAT_AMOUNT"
                            ? "amount"
                            : "discount",
                          e.target.value
                        )
                      }
                      value={
                        watch("discount_type") === "FLAT_AMOUNT"
                          ? watch("amount")
                          : watch("discount")
                      }
                    />
                  </div>
                  <CouponsSwitchType
                    discountType={watch("discount_type")}
                    onChangeHandler={setValue}
                  />
                </div>
                <LabelledInput
                  id="quantity"
                  placeholder="How many of these vouchers would you like to create"
                  label="How many of these vouchers would you like to create"
                  name="quantity"
                  onChange={(e) => setValue("quantity", e.target.value)}
                  value={watch("quantity")}
                />
                <div className="flex flex-col lg:flex-row lg:justify-between items-center w-full space-y-2 lg:space-y-0 lg:space-x-[4rem]">
                  <DatePickerInputV2
                    name="Start Date"
                    id="startDate"
                    onChange={(e) => setValue("valid_from", e.target.value)}
                    value={watch("valid_from")}
                  />
                  <DatePickerInputV2
                    name="End Date"
                    id="endDate"
                    onChange={(e) => setValue("valid_until", e.target.value)}
                    value={watch("valid_until")}
                  />
                </div>
                <div className="h-[20rem] w-full relative">
                  <div className="w-full h-[20rem] relative">
                    <input
                      className="absolute h-full w-full"
                      type="file"
                      id="businessBannerImageInput"
                      onChange={(e) => setValue("media", e.target.files[0])}
                      name="businessBannerImage"
                      accept="image/*"
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    {watch("media") === null ? (
                      <div className="form-control flex flex-col justify-center items-center h-full w-full px-3 py-1.5 text-sm font-medium text-base bg-white bg-clip-padding border border-solid border-[#D1D9DD] rounded-lg transition ease-in-out m-0 focus:bg-white focus:border-primary-50 thryve:focus:border-secondary-50 focus:outline-none focus:shadow thryve:focus:shadow-[#0061874D]">
                        <img
                          src={Image}
                          alt="Upload Banner"
                          className="mb-4"
                          aria-label="Upload Ad Image"
                          title="Upload Ad Image"
                        />
                        <p>Add an image</p>
                      </div>
                    ) : (
                      <img
                        src={URL.createObjectURL(watch("media"))}
                        alt="offer"
                        className="w-full h-full object-cover rounded-lg"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="relative p-4 w-full">
          <Button
            className="font-semibold flex justify-center items-center"
            onClick={async () => {
              if (makeOnlineOffer === false) {
                createNewOfferHandler(navigate);
              } else {
                await persistentCouponDataHandler(() =>
                  createNewOfferHandler(navigate)
                );
              }
            }}
          >
            {isCreatingOffer || isCreatingCoupon
              ? `Creating campaign..`
              : `Continue`}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
