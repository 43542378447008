import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useAuth } from "../Contexts/V2/AuthenticationContext";
import {
  createCouponProgram,
  deleteCoupon,
  fetchAllCouponProgram,
  fetchAllCoupons,
  pauseCoupon,
  redeemCoupon,
} from "../requests-v2";

const useCoupons = ({ couponProgramID }) => {
  const { user } = useAuth();
  const [isCreatingCoupon, setIsCreatingCoupon] = useState(false);
  const [isLoadingCouponsPrograms, setIsLoadingCouponsPrograms] =
    useState(false);
  const [isFetchingCoupons, setIsFetchingCoupons] = useState(false);
  const [isRedeemingCoupons, setIsRedeemingCoupons] = useState(false);
  const [couponPrograms, setCouponPrograms] = useState(null);
  const [coupons, setCoupons] = useState(null);
  const [couponCode, setCouponCode] = useState(null);
  const [isPausedData, setIsPausedData] = useState(null);
  const [makeOnlineOffer, setMakeOnlineOffer] = useState(false);
  const [isDeletingCoupon, setIsDeletingCoupon] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMoreCoupons, setHasMoreCoupons] = useState(true);
  const [showRedeemOfferPrompt, setShowRedeemOfferPrompt] = useState(false);
  const offerData = JSON.parse(localStorage.getItem("NEW_OFFER"));

  const {
    setValue,
    setError,
    reset,
    getValues,
    watch,
    formState: { errors, defaultValues },
  } = useForm({
    defaultValues: {
      title: offerData?.title || "",
      description: offerData?.description || "",
      discount_type: "PERCENTAGE",
      discount: 0,
      amount: 0,
      quantity: 0,
      valid_from: moment().format("YYYY-MM-DDTHH:mm"),
      valid_until: moment().add(1, "week").format("YYYY-MM-DDTHH:mm"),
      media: null,
    },
  });

  const validateDiscount = (data) => {
    let isValid = true; // Assume valid unless proven otherwise

    // Required fields validation
    const requiredFields = [
      "title",
      "description",
      "discount_type",
      "valid_from",
      "valid_until",
      "media",
    ];

    requiredFields.forEach((field) => {
      if (
        !data[field] ||
        (typeof data[field] === "string" && data[field].trim() === "")
      ) {
        toast.error(`${field.replace("_", " ")} cannot be empty`);
        isValid = false;
      }
    });

    if (data.quantity === 0) {
      toast.error("quantity cannot be zero when discount type is PERCENTAGE");
      isValid = false;
    }

    // Discount validation based on type
    if (
      data.discount_type === "PERCENTAGE" &&
      (!data.discount || data.discount === 0)
    ) {
      toast.error("Discount cannot be zero when discount type is PERCENTAGE");
      isValid = false;
    }

    if (
      data.discount_type === "FLAT_AMOUNT" &&
      (!data.amount || data.amount === 0)
    ) {
      toast.error("Amount cannot be zero when discount type is FLAT_AMOUNT");
      isValid = false;
    }

    return isValid; // Only returns true if ALL checks pass
  };

  const persistentCouponDataHandler = async (callback) => {
    const formData = getValues(); // Get form values once
    const isValid = validateDiscount(formData); // Validate everything

    if (!isValid) {
      console.warn("Validation failed, callback will NOT run.");
      return; // Prevent callback from running
    }

    setIsCreatingCoupon(true);

    // Convert data to FormData
    const payload = new FormData();

    // Append required fields
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        payload.append(key, value);
      }
    });

    // Remove unnecessary fields based on discount type
    if (formData.discount_type === "PERCENTAGE") {
      payload.delete("amount");
    }
    if (formData.discount_type === "FLAT_AMOUNT") {
      payload.delete("discount");
    }

    try {
      const response = await createCouponProgram(user?.id, payload);

      if (response.status === 201) {
        localStorage.setItem("isOnlineOffer", JSON.stringify(true));
        reset(defaultValues);
        callback(); // Runs only if API call succeeds
      }
    } catch (e) {
      console.error("An error occurred while creating offer", e);
      toast.error("An error occurred while creating offer");
    } finally {
      setIsCreatingCoupon(false);
    }
  };

  const creatCouponHandler = async () => {};

  const redeemCouponHandler = async () => {
    console.log("I got clicked");
    if (couponCode.length > 0) {
      setIsRedeemingCoupons(true);
      try {
        const response = await redeemCoupon(user?.id, couponCode);

        if (response.status === 200) {
          toast.success("Successfully redeemed coupon");
          setShowRedeemOfferPrompt(false);
        } else {
          throw new Error();
        }
      } catch (e) {
        console.log("An error occurred while redeeming coupon", e);
        toast.error("An error occurred while redeeming coupon");
      } finally {
        setIsRedeemingCoupons(false);
      }
    } else {
      toast.error("Coupon code is required");
    }
  };

  const pauseCouponHandler = async (isPaused) => {
    if (couponProgramID) {
      try {
        setIsPausedData(true);
        const response = await pauseCoupon(isPaused, couponProgramID);
        if (response.status === 200) {
          toast.success(
            isPaused
              ? "Successfully paused coupon"
              : "Successfully unpause coupon"
          );
          // refresh page using windows object
          window.location.reload();
        }
      } catch (e) {
        console.error("An error occurred while pausing/unpausing coupon", e);
        toast.error(
          isPaused
            ? "An error occurred while pausing coupon"
            : "An error occurred while unpausing coupon"
        );
      } finally {
        setIsPausedData(false);
      }
    }
  };

  const deleteCouponHandler = async (navigate) => {
    if (couponProgramID) {
      try {
        setIsDeletingCoupon(true);
        const response = await deleteCoupon(couponProgramID);
        if (response.status === 200) {
          toast.success("Successfully deleted coupon");
          navigate("/promotions?tab=coupons");
        }
      } catch (e) {
        console.error("An error occurred while deleting coupon", e);
        toast.error("An error occurred while deleting coupon");
      } finally {
        setIsDeletingCoupon(false);
      }
    }
  };

  const fetchMoreCouponPrograms = async () => {
    if (hasMoreCoupons) {
      setIsFetchingMore(true);
      const newOffset = offset + 50;
      try {
        const response = await fetchAllCouponProgram(user?.id, newOffset);
        if (response.length === 0) {
          setHasMoreCoupons(false);
        } else {
          setCouponPrograms([...couponPrograms, ...response]);
          setOffset(newOffset);
        }
      } catch (e) {
        console.error("Error fetching coupon programs", e);
      } finally {
        setIsFetchingMore(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoadingCouponsPrograms(true);
      try {
        const response = await fetchAllCouponProgram(user?.id);
        setCouponPrograms(response?.filter((c) => c?.is_deleted === false));
      } catch (e) {
        console.error("Error fetching coupon programs", e);
      } finally {
        setIsLoadingCouponsPrograms(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (couponProgramID) {
        setIsFetchingCoupons(true);
        try {
          const response = await fetchAllCoupons(couponProgramID);
          setCoupons(response?.filter((c) => c?.is_redeemed === true));
        } catch (e) {
          console.error("Error fetching coupon programs", e);
        } finally {
          setIsFetchingCoupons(false);
        }
      }
    })();
  }, []);

  console.log("coupons", coupons);

  return {
    isCreatingCoupon,
    setValue,
    setError,
    errors,
    creatCouponHandler,
    isLoadingCoupons: isLoadingCouponsPrograms,
    couponPrograms,
    coupons,
    isFetchingCoupons,
    redeemCouponHandler,
    isRedeemingCoupons,
    setCouponCode,
    setIsPausedData,
    makeOnlineOffer,
    setMakeOnlineOffer,
    getValues,
    watch,
    persistentCouponDataHandler,
    deleteCouponHandler,
    isDeletingCoupon,
    pauseCouponHandler,
    isPausedData,
    setShowRedeemOfferPrompt,
    showRedeemOfferPrompt,
    fetchMoreCouponPrograms,
    isFetchingMore,
    hasMoreCoupons,
  };
};

export default useCoupons;
