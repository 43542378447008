/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

export default function DatePickerInputV2({ name, id, onChange, value }) {
  return (
    <div className="relative px-2.5 py-1.5 w-full flex flex-row justify-between item-center border border-solid border-[#D1D9DD] rounded-lg transition ease-in-out m-0 focus-within:bg-white focus-within:border-primary-50 thryve:focus-within:border-secondary-50 focus-within:outline-none focus-within:shadow thryve:focus-within:shadow-[#0061874D]">
      <div className="w-full">
        <p className="text-xs text-gray-500">{name}</p>
        <input
          value={value}
          name="date-picker"
          type="datetime-local"
          id={id}
          onChange={onChange}
          className="form-control block w-full h-6 text-sm font-medium text-base bg-white border-transparent focus:border-transparent focus:ring-0 outline-none"
        />
      </div>
      <div className="absolute h-full w-[10%] flex justify-end items-center right-2.5 top-2.5 -z-40">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2H5V0H7V2H13V0H15V2ZM13 4H7V6H5V4H2V8H18V4H15V6H13V4ZM18 10H2V18H18V10Z"
            fill="#566370"
          />
        </svg>
      </div>
    </div>
  );
}

DatePickerInputV2.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props,react/forbid-prop-types
  value: PropTypes.any.isRequired,
};

// export default function DatePickerInput({
//   name,
//   rules,
//   className,
//   id,
//   register,
//   placeholder,
//   label,
//   wrapperClassName,
//   labelClassName,
//   helperText,
//   ...rest
// }) {
//   return (
//     <div className={wrapperClassName}>
//       <input
//         type="date"
//         className={className}
//         name={name}
//         id={id}
//         {...register(name)}
//         {...rules}
//         placeholder={placeholder || " "} // This is required for the label to float
//         {...rest}
//       />
//       <label htmlFor={id} className={labelClassName}>
//         {label}
//       </label>
//       {helperText && <p className="text-sm text-gray-500 my-2">{helperText}</p>}
//     </div>
//   );
// }
//
// DatePickerInput.propTypes = {
//   name: PropTypes.string.isRequired,
//   rules: PropTypes.shape({}),
//   type: PropTypes.string,
//   className: PropTypes.string,
//   id: PropTypes.string.isRequired,
//   register: PropTypes.func,
//   wrapperClassName: PropTypes.string,
//   label: PropTypes.string.isRequired,
//   labelClassName: PropTypes.string,
//   placeholder: PropTypes.string,
//   helperText: PropTypes.string,
// };
//
// DatePickerInput.defaultProps = {
//   rules: {},
//   type: "text",
//   wrapperClassName: "date-picker form-floating mb-3 w-full",
//   className:
//     "form-control block w-full px-3 py-1.5 text-sm font-medium text-base bg-white bg-clip-padding border border-solid border-[#D1D9DD] rounded-lg transition ease-in-out m-0 focus:bg-white focus:border-primary-50 thryve:focus:border-secondary-50 focus:outline-none focus:shadow thryve:focus:shadow-[#0061874D]",
//   labelClassName: "text-gray-500",
//   placeholder: "",
//   register: () => {},
//   helperText: "",
// };
