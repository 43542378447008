import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { toast } from "react-hot-toast";
import {
  getMerchantProductPhotos,
  createMerchantProductPhotos,
  deleteMerchantProductPhotos,
  createMerchantProductCategory,
  getMerchantProductCategories,
  uploadProductPhotosMiniApp,
  updateProductDetailsMiniAppHandler,
} from "../../requests-v2";
import { useAuth } from "./AuthenticationContext";

const ProductPhotosContext = createContext(null);

function useProductPhotosContext() {
  return useContext(ProductPhotosContext);
}

// The ProductPhotosProvider is used to store the business context, eg countryCode, name, logo, etc
function ProductPhotosProvider({ children }) {
  const { isAuthenticated, merchantId } = useAuth();

  const [newProduct, setNewProduct] = useState({
    product_name: "",
    price: 0,
    description: "",
    product_category_id: 0,
    product_category_name: "",
    product_link: "",
    photo_url: "",
  });
  const [productCategories, setProductCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [showAddCategoryPrompt, setShowAddCategoryPrompt] = useState(false);
  const [productPhotos, setProductPhotos] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [businessPhoto, setBusinessPhoto] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showUploadConfirmation, setShowUploadConfirmation] = useState(false);
  const [errors, setErrors] = useState({});

  const newProductHandler = (e) => {
    if (e.target.id !== "product_price") {
      setNewProduct({
        ...newProduct,
        [e.target.id]: e.target.value,
      });
    } else if (e.target.id === "product_price") {
      setNewProduct({ ...newProduct, price: parseInt(e.target.value, 10) });
    }
  };

  const onBlurHandler = (e) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    if (e.target.id === "product_name") {
      if (e.target.value === "") {
        setErrors({ ...errors, product_name: "Product name is required" });
      } else {
        // eslint-disable-next-line camelcase
        const { product_name, ...rest } = errors;
        setErrors(rest);
      }
    }

    if (e.target.id === "product_link") {
      if (e.target.value !== "" && !urlPattern.test(e.target.value)) {
        setErrors({
          ...errors,
          product_link: "Please provide a valid URL eg https://google.com",
        });
      } else {
        // eslint-disable-next-line camelcase
        const { product_link, ...rest } = errors;
        setErrors(rest);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSize = 15 * 1024 * 1024; // 15MB in bytes
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        setBusinessPhoto([file, ...businessPhoto]);
      } else {
        toast.error("Image must be a jpeg, png, jpg and less than 15MB");
      }
    }
  };

  const selectCategoryHandler = (category) => {
    setNewProduct({
      ...newProduct,
      product_category_id: category.id,
      product_category_name: category.category_name,
    });
  };

  const updateProductPhotos = (data) => {
    setProductPhotos(data);
  };

  const updateProductCateogories = (data) => {
    setProductCategories(data);
  };

  const query = new URLSearchParams({ page: 1, size: 24 });

  const queryClient = useQueryClient();

  const { data: products } = useQuery(
    ["/product-photos", query.toString(), merchantId],
    () => getMerchantProductPhotos({ query }),
    { enabled: !!isAuthenticated }
  );

  const { data: categories } = useQuery(
    ["/product-photos/category", query.toString(), merchantId],
    () => getMerchantProductCategories(),
    { enabled: !!isAuthenticated }
  );

  const { mutateAsync: mutateCreateProductPhoto } = useMutation(
    (data) => createMerchantProductPhotos(data),
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries({
          queryKey: ["/product-photos"],
          type: "all",
        });
        setIsLoading(false);
        setShowUploadConfirmation(true);
        setBusinessPhoto([]);
        Promise.resolve(res);
      },
      onError: (error) => {
        setIsLoading(false);
        Promise.reject(error);
      },
    }
  );

  const { mutateAsync: mutateCreateProductCategory } = useMutation(
    () => createMerchantProductCategory(newCategory),
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries({
          queryKey: ["/product-photos/category"],
          type: "all",
        });
        setNewCategory("");
        setIsCreatingCategory(false);
        setShowAddCategoryPrompt(false);
        Promise.resolve(res);
      },
      onError: (error) => {
        setIsLoading(false);
        Promise.reject(error);
      },
    }
  );

  // Get merchant photos ... This should only run if we have user data
  useEffect(() => {
    updateProductPhotos(products);
    updateProductCateogories(categories);
  }, [products, categories, merchantId]);

  const showDeleteConfirmationHandler = () => {
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationHandler = () => {
    setShowDeleteConfirmation(false);
  };

  const { mutateAsync: mutateDeleteProductPhoto } = useMutation(
    (id) => deleteMerchantProductPhotos({ id }),
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: ["/product-photos"],
          type: "all",
        });
        setIsLoading(false);
        hideDeleteConfirmationHandler();
        Promise.resolve(res);
      },
      onError: (error) => {
        setIsLoading(false);
        Promise.reject(error);
      },
    }
  );

  const uploadProductPhoto = async () => {
    if (
      businessPhoto.length > 0 &&
      errors.product_name === undefined &&
      errors.product_link === undefined &&
      newProduct.product_name.length > 0
    ) {
      setIsLoading(true);

      const formData = new FormData();
      businessPhoto.forEach((file) => {
        formData.append("images", file);
      });

      formData.append("product_name", newProduct.product_name);

      if (newProduct.price) {
        formData.append("price", newProduct.price);
      }

      if (newProduct.description) {
        formData.append("description", newProduct.description);
      }

      if (newProduct.product_category_id) {
        formData.append("product_category_id", newProduct.product_category_id);
      }

      if (newProduct.product_link) {
        formData.append("product_link", newProduct.product_link);
      }

      if (newProduct.product_category_name) {
        formData.append(
          "product_category_name",
          newProduct.product_category_name
        );
      }

      await mutateCreateProductPhoto(formData);
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    }
  };

  const createNewCategory = async () => {
    if (newCategory) {
      setIsCreatingCategory(true);
      await mutateCreateProductCategory(newCategory);
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    }
  };

  const deleteProductPhoto = async (photoId) => {
    setIsLoading(true);

    await mutateDeleteProductPhoto(photoId);
  };

  const updateProductPhotoDetailsHandler = (photoId) => {
    const updatedData = {
      product_name: newProduct.product_name,
      product_urls: businessPhoto.map((p) => p.photo_url),
    };

    if (newProduct.price) {
      updatedData.price = newProduct.price;
    }

    if (newProduct.description) {
      updatedData.description = newProduct.description;
    }

    if (newProduct.product_category_id) {
      updatedData.product_category_id = newProduct.product_category_id;
    }

    if (newProduct.product_link) {
      updatedData.product_link = newProduct.product_link;
    }

    if (newProduct.product_category_name) {
      updatedData.product_category_name = newProduct.product_category_name;
    }

    if (
      (businessPhoto || newProduct.photo_url) &&
      errors.product_name === undefined &&
      errors.product_link === undefined
    ) {
      toast.loading("Updating product photo", { id: "updating-product-photo" });
      updateProductDetailsMiniAppHandler(photoId, updatedData)
        .then((res) => {
          toast.dismiss("updating-product-photo");
          setShowUploadConfirmation(true);
          Promise.resolve(res);
        })
        .catch((error) => {
          toast.error("Failed to update product photo", {
            id: "updating-product-photo",
          });
          Promise.reject(error);
        });
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    }
  };

  const uploadProductPhotoMiniAppHandler = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSize = 15 * 1024 * 1024; // 15MB in bytes
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        toast.loading("Uploading product photo", {
          id: "uploading-product-photo",
        });
        try {
          const formData = new FormData();
          formData.append("image", file);
          const response = await uploadProductPhotosMiniApp(formData);
          setBusinessPhoto((prev) => [
            ...prev,
            { photo_url: response.product_url },
          ]);
          toast.success("Product photo uploaded successfully", {
            id: "uploading-product-photo",
          });
        } catch (e) {
          console.info("Could not upload photo", e);
          toast.error("Failed to upload product photo", {
            id: "uploading-product-photo",
          });
        }
      } else {
        toast.error("Image must be a jpeg, png, jpg and less than 15MB");
      }
    }
  };

  const value = useMemo(() => ({
    updateProductPhotos,
    productPhotos,
    isLoading,
    deleteProductPhoto,
    showDeleteConfirmationHandler,
    hideDeleteConfirmationHandler,
    showDeleteConfirmation,
    uploadProductPhoto,
    setBusinessPhoto,
    businessPhoto,
    showError,
    showUploadConfirmation,
    setShowUploadConfirmation,
    setNewCategory,
    newCategory,
    isCreatingCategory,
    showAddCategoryPrompt,
    setShowAddCategoryPrompt,
    newProductHandler,
    selectCategoryHandler,
    createNewCategory,
    productCategories,
    newProduct,
    setNewProduct,
    updateProductPhotoDetailsHandler,
    onBlurHandler,
    errors,
    handleFileChange,
    uploadProductPhotoMiniAppHandler,
  }));

  return (
    <ProductPhotosContext.Provider value={value}>
      {children}
    </ProductPhotosContext.Provider>
  );
}

export { useProductPhotosContext, ProductPhotosProvider };

ProductPhotosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
