import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/Contexts/V2/AuthenticationContext";
import ShareIcon from "../assets/icons/share-webpage-icon.svg";
import OffersIcon from "../assets/icons/send-offers-icon.svg";
import { useOffer } from "../utils/Contexts/V2/OfferContext";
import addProductPhoto from "../assets/icons/add_product_photo.svg";
import addCustomer from "../assets/icons/qa-customers.svg";

function QuickActionTilesItem({ onClickHandler, icon, label, color }) {
  return (
    <>
      <button
        type="button"
        onClick={onClickHandler}
        className="hidden flex-col items-center space-y-2.5 flex-shrink-0"
      >
        <div
          style={{ backgroundColor: color }}
          className="h-16 w-16 flex justify-center items-center bg-sendOffer cursor-pointer rounded-2xl"
        >
          <img src={icon} alt={label} />
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="text-xs font-medium">{label.split(" ")[0]}</p>
          <p className="text-xs font-medium">{label.split(" ")[1]}</p>
        </div>
      </button>
      <button
        key={label}
        type="button"
        onClick={onClickHandler}
        style={{ backgroundColor: color }}
        className="flex grow flex-row items-center justify-start space-x-[1.5rem] rounded-[1rem] px-[1.25rem]  py-[1.35rem]"
      >
        <div>
          <img src={icon} alt={label} />
        </div>
        <p className="font-bold text-[0.75rem] md:text-[1rem] text-[#566370] text-left">
          {label}
        </p>
      </button>
    </>
  );
}

QuickActionTilesItem.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

function QuickActionTiles({ showDialogue, setShowAddCustomerPrompt }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setShowAITextMarketingPrompt } = useOffer();

  const businessName = user?.business_name;
  const subdomain = user?.subdomain;
  const webLink = `https://${subdomain}.tappi.ke`;
  const shareText = `Hello Visit ${businessName}'s website to find out more about my business. You can call or chat with us on WhatsApp as well as leave a review so we can serve you better`;

  const shareDialogueHandler = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check us out!",
          text: shareText,
          url: webLink,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      showDialogue(true, "Share Webpage");
    }
  };

  const QUICK_ACTION_ITEMS = [
    {
      label: "Text Marketing",
      icon: OffersIcon,
      color: "rgba(227, 115, 51, 0.12)",
      action: () => setShowAITextMarketingPrompt(true),
    },
    {
      label: "Add Product",
      icon: addProductPhoto,
      color: "rgba(134,116,254, 0.2)",
      action: () => navigate("/add-photos"),
    },
    {
      label: "Add Customer",
      icon: addCustomer,
      color: "rgba(2,135,82, 0.2)",
      action: () => setShowAddCustomerPrompt(true),
    },
    {
      label: "Share Webpage",
      icon: ShareIcon,
      color: "rgba(203, 4, 255, 0.12)",
      action: () => shareDialogueHandler(),
    },
  ];

  return (
    <div className="w-full grid grid-cols-2 gap-[1rem] md:gap-[2rem]">
      {QUICK_ACTION_ITEMS.map((qa) => (
        <QuickActionTilesItem
          onClickHandler={qa.action}
          icon={qa.icon}
          label={qa.label}
          color={qa.color}
        />
      ))}
    </div>
  );
}

QuickActionTiles.propTypes = {
  showDialogue: PropTypes.func.isRequired,
  setShowAddCustomerPrompt: PropTypes.func.isRequired,
};

export default QuickActionTiles;
