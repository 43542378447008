import React from "react";
import PropTypes from "prop-types";

function DeleteConfirmationPrompt({
  text,
  action,
  closePrompt,
  isDeleting,
  buttonText,
}) {
  return (
    <div className="relative w-screen h-screen  flex justify-center items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute h-full w-full bg-black/[.6]"
        onClick={() => closePrompt(false)}
      />
      <div className="absolute z-20 flex flex-col justify-start items-center space-y-5 w-full px-4 pt-[1.7rem] pb-6 bg-white rounded-t-2xl md:rounded-2xl self-end md:self-center lg:max-w-screen-sm">
        <p className="text-base font-semibold">Are you sure?</p>
        <p className="text-base font-normal text-center">{text}</p>
        <div className="flex flex-col space-y-3 w-full">
          <button
            type="button"
            className="w-full h-12 text-white text-base font-semibold rounded-xl bg-primary-50 thryve:text-base"
            onClick={action}
          >
            {!isDeleting ? `${buttonText || "Yes, Delete"}` : "Processing..."}
          </button>
          <button
            type="button"
            className="w-full h-12 text-black text-base font-semibold"
            onClick={() => closePrompt(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

DeleteConfirmationPrompt.defaultProps = {
  buttonText: "Yes, Delete",
};

DeleteConfirmationPrompt.propTypes = {
  buttonText: PropTypes.string,
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  closePrompt: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
};

export default DeleteConfirmationPrompt;
