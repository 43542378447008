/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { useForm, useWatch } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import Button from "../../../components/forms/Button";
import Layout from "../../../components/layout/V2/Layout";
import { useContact } from "../../../utils/Contexts/V2/ContactContext";
import { useOffer } from "../../../utils/Contexts/V2/OfferContext";
import useDebounce from "../../../utils/useDebounce";
import { API_PATHS, searchContact } from "../../../utils/requests-v2";

export default function SelectCustomer() {
  const { contacts, totalContacts } = useContact();
  const {
    selectedCustomersForOffer,
    selectedCustomersForOfferHandler,
    setSelectedCustomersForOffer,
    newOffer,
    newOfferHandler,
  } = useOffer();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const displayPicHandler = (name) => {
    const abbreviatedName = name ? name?.toUpperCase().split(" ") : "";
    return `${abbreviatedName[0].charAt(0)}`;
  };

  useEffect(() => {
    setSelectedCustomersForOffer([]);
  }, []);

  const continueButtonHandler = () => {
    if (selectedCustomersForOffer.length > 0 || newOffer?.select_all_contacts) {
      setShowError(false);
      navigate(
        newOffer?.select_all_contacts
          ? `/create-offers/review-offer/${id}?selected_all=${newOffer?.select_all_contacts}`
          : `/create-offers/review-offer/${id}`
      );
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  };

  const { register, control } = useForm({
    defaultValues: {
      searchString: "",
    },
  });

  const [filteredContacts, setFilteredContacts] = useState(contacts);

  const contactSearch = useWatch({ control, name: "searchString" });
  const debouncedContactName = useDebounce(contactSearch, 1000);

  useEffect(() => {
    if (contacts && !contactSearch) {
      setFilteredContacts(contacts);
    }
  }, [contacts, contactSearch]);

  useEffect(() => {
    if (!debouncedContactName) {
      setFilteredContacts(contacts);
    }
  }, [debouncedContactName]);

  const { isFetching: isFetchingContacts } = useQuery(
    [API_PATHS.searchContacts, debouncedContactName],
    async () => {
      try {
        const contactResults = await searchContact(debouncedContactName);
        setFilteredContacts(contactResults.data.data);
        return contactResults;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    {
      enabled: !!debouncedContactName,
    }
  );

  return (
    <Layout headerTitle="Create Contact" header>
      <div className="relative px-4 w-full md:max-w-screen-lg h-screen scroll-smooth pt-20 flex flex-col items-center justify-between">
        <Transition
          className="fixed left-0 right-0 -bottom-12 z-20 w-full flex justify-center items-center shadow-xl mb-4"
          show={showError}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <p className="py-2.5 px-4 text-red-50 text-sm font-bold w-64 text-center bg-white rounded-[6.25rem] mt-72">
            Please select at least one contact
          </p>
        </Transition>
        <div className="px-4 flex flex-col justify-center items-center space-y-4">
          <div className="relative w-[15.56rem] h-1 rounded-lg bg-primary-20">
            <div className="w-[50%] bg-primary-50 h-1 rounded-lg" />
          </div>
          <p className="px-7 font-normal text-base font-sm text-center">
            Who would you like to send this text marketing campaign to?
          </p>
        </div>
        <div className="w-full px-4 pt-4">
          <div className="flex flex-row justify-between items-center gap-4 border border-solid rounded-lg px-3 py-1">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.3335 0.333252C9.6455 0.333252 12.3335 3.02125 12.3335 6.33325C12.3335 9.64525 9.6455 12.3333 6.3335 12.3333C3.0215 12.3333 0.333496 9.64525 0.333496 6.33325C0.333496 3.02125 3.0215 0.333252 6.3335 0.333252ZM6.3335 10.9999C8.9115 10.9999 11.0002 8.91125 11.0002 6.33325C11.0002 3.75459 8.9115 1.66659 6.3335 1.66659C3.75483 1.66659 1.66683 3.75459 1.66683 6.33325C1.66683 8.91125 3.75483 10.9999 6.3335 10.9999ZM11.9902 11.0473L13.8762 12.9326L12.9328 13.8759L11.0475 11.9899L11.9902 11.0473Z"
                fill="#808A93"
              />
            </svg>
            <input
              className="h-full w-full py-2 text-xs outline-none"
              placeholder="Search"
              {...register("searchString")}
              disabled={isFetchingContacts}
            />
          </div>{" "}
        </div>
        <div className="w-full h-full flex flex-col justify-start pt-[0.69rem] px-4 overflow-y-auto">
          <div className="py-4 px-2 flex flex-row justify-between items-center w-full">
            <p className="font-bold text-sm">
              Selected contacts (
              {!newOffer?.select_all_contacts
                ? selectedCustomersForOffer.length
                : totalContacts}
              /{totalContacts})
            </p>
            <div className="flex flex-row justify-end items-center space-x-3">
              <p className="font-bold text-sm">Select all</p>
              <input
                className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:text-primary-50 checked:border-white checked:outline-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                name="flexRadioDefault"
                id="select_all_contacts"
                onChange={newOfferHandler}
                checked={newOffer?.select_all_contacts || false}
              />
            </div>
          </div>
          {newOffer?.select_all_contacts ? (
            <div className="flex h-[20rem] w-full flex-col items-center justify-center space-y-[0.75rem]">
              <div className="flex h-[14.5rem] w-[14.5rem] items-center justify-center rounded-full bg-[#F8F6FB]">
                <svg
                  width="130"
                  height="130"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                    stroke="#4B1D8F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 11L19 13L23 9"
                    stroke="#4B1D8F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.5 11C10.7091 11 12.5 9.20914 12.5 7C12.5 4.79086 10.7091 3 8.5 3C6.29086 3 4.5 4.79086 4.5 7C4.5 9.20914 6.29086 11 8.5 11Z"
                    stroke="#4B1D8F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p className="text-center w-full max-w-xl">
                ⚠️ All of your {totalContacts} contacts will receive this
                message. Please be aware that it will be sent to all contacts
                saved in your customer list.
              </p>
            </div>
          ) : (
            filteredContacts
              ?.sort((a, b) => a.full_name.localeCompare(b.full_name))
              ?.map((item) => (
                <div
                  className="w-full flex flex-row justify-between items-center py-2 border-t border-b border-[#F8F6FB]"
                  key={item?.id}
                >
                  <div className="flex flex-row items-center space-x-[0.69rem]">
                    <p className="w-10 h-10 bg-[#D9D9D9] rounded-full p-4 flex justify-center items-center font-bold">
                      {displayPicHandler(item?.full_name)}
                    </p>
                    <div className="flex flex-col justify-between items-start">
                      <p className="font-bold text-sm">{item?.full_name}</p>
                      <p className="font-normal text-xs text-[#566370]">
                        {item?.phone_number}
                      </p>
                    </div>
                  </div>
                  <input
                    className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:text-primary-50 checked:border-white checked:outline-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    name="flexRadioDefault"
                    id={item?.name}
                    onChange={(e) => selectedCustomersForOfferHandler(e, item)}
                  />
                </div>
              ))
          )}
        </div>
        <div className="relative p-4 w-full">
          <Button
            className="font-semibold flex justify-center items-center"
            onClick={continueButtonHandler}
          >
            Continue
          </Button>
        </div>
      </div>
    </Layout>
  );
}
