import moment from "moment";

function couponStatus(coupon) {
  const now = moment();

  const validFrom = moment(coupon?.valid_from);
  const validUntil = moment(coupon?.valid_until);

  if (coupon?.is_paused && now.isBefore(validUntil)) {
    return { status: "Paused", color: "#fd7031" };
  }

  if (now.isBefore(validFrom)) {
    return { status: "Scheduled", color: "#fd7031" };
  }
  if (now.isBefore(validUntil)) {
    if (coupon?.claimed_coupons < coupon?.quantity) {
      return { status: "Live", color: "#45C78A" };
    }
    return { status: "Inactive", color: "#98A2B3" };
  }
  return { status: "Expired", color: "#F04438" };
}

export default couponStatus;
