import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import DefaultImage from "../../assets/images/coupon-default.svg";
import couponStatus from "../../CouponStatus";

function CouponCard({ coupon }) {
  return (
    <div className="flex flex-col justify-between items-start border-[1px] border-[#E7E7E7] rounded-[0.75rem] p-[0.75rem] space-y-[0.75rem] w-full">
      <div className="flex flex-row justify-between items-center w-full">
        <p className="text-[#1A1A1A] text-[0.875rem] font-bold">
          {coupon?.amount !== 0
            ? `${coupon?.amount} off`
            : `${coupon?.discount}%`}
        </p>
        <Link
          to={`/promotions/coupon/${coupon?.id}`}
          className="bg-[rgba(219,_216,_212,_0.45)] h-[1.5rem] w-[1.5rem] flex justify-center items-center rounded-full "
        >
          <LinkIcon />
        </Link>
      </div>
      {!coupon?.media_url ? (
        <div className="w-full h-[10.25rem] rounded-[0.5rem] bg-[#F4F4F4]">
          <img
            src={DefaultImage}
            alt={coupon?.title}
            className="w-full h-full object-contain object-center"
          />
        </div>
      ) : (
        <img
          src={coupon?.media_url}
          alt={coupon?.title}
          className="w-full h-[10.25rem] rounded-[0.5rem] object-cover object-center"
        />
      )}
      <p className="leading-[1rem] text-[0.75rem] text-[#566370]">
        {coupon?.description}
      </p>
      <div className="flex flex-row justify-between items-center w-full h-[1.63rem] divide-x-[1px] divide-[#E7E7E7]">
        <div className="flex flex-col justify-start items-start space-y-[0.31rem] px-[0.5rem]">
          <div className="flex flex-row justify-start items-center space-x-[0.25rem]">
            <div
              style={{
                backgroundColor: couponStatus(coupon).color,
              }}
              className="h-[0.63rem] w-[0.63rem] rounded-full"
            />
            <p className="text-[#1A1A1A] text-[0.63rem] font-bold">
              {couponStatus(coupon).status}
            </p>
          </div>
          <p className="text-[#1A1A1A] text-[0.5rem]">Status</p>
        </div>
        <div className="flex flex-col justify-start items-start space-y-[0.31rem] px-[0.5rem]">
          <p className="text-[#1A1A1A] text-[0.63rem] font-bold">
            {moment(coupon?.created_at).format("DD/MM/YYYY")}
          </p>
          <p className="text-[#1A1A1A] text-[0.5rem]">Created on</p>
        </div>
        <div className="flex flex-col justify-start items-start space-y-[0.31rem] px-[0.5rem]">
          <p className="text-[#1A1A1A] text-[0.63rem] font-bold">
            {moment(coupon?.valid_until).format("DD/MM/YYYY")}
          </p>
          <p className="text-[#1A1A1A] text-[0.5rem]">Expires on</p>
        </div>
      </div>
    </div>
  );
}

CouponCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  coupon: PropTypes.object.isRequired,
};

function LinkIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.22973 15L7 13.8333L11.0405 10L7 6.16667L8.22973 5L13.5 10L8.22973 15Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export default CouponCard;
