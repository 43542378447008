import React from "react";
import Switch from "react-switch";
import PropTypes from "prop-types";

export default function CouponsSwitchType({ discountType, onChangeHandler }) {
  return (
    <Switch
      handleDiameter={30}
      offColor="#E7E7E7"
      onColor="#E7E7E7"
      offHandleColor="#FFF"
      onHandleColor="#FFF"
      height={40}
      width={101}
      borderRadius={8}
      uncheckedIcon={
        <p className="ml-3 mt-1 flex h-[30px] w-[40px] items-center justify-center bg-[#FFF] text-[14px] font-bold text-[#1A1A1A] opacity-20">
          Ksh.
        </p>
      }
      checkedIcon={
        <p className="absolute left-1.5 top-1 flex h-[30px] w-[40px] items-center justify-center bg-[#FFF] text-[14px] font-bold text-[#1A1A1A] opacity-20">
          %
        </p>
      }
      uncheckedHandleIcon={
        <p className="mr-10 flex h-[30px] w-[40px] items-center justify-center bg-white text-[14px] font-bold text-[#1A1A1A]">
          %
        </p>
      }
      checkedHandleIcon={
        <p className="absolute right-1 flex h-[30px] w-[40px] items-center justify-center rounded-none bg-white text-[14px] font-bold text-[#1A1A1A]">
          Ksh.
        </p>
      }
      id="discount_type"
      checked={discountType !== "PERCENTAGE"}
      onChange={() =>
        onChangeHandler(
          "discount_type",
          discountType === "PERCENTAGE" ? "FLAT_AMOUNT" : "PERCENTAGE"
        )
      }
      className="relative mt-1.5 flex flex-row items-center justify-between rounded-[0.25rem] "
    />
  );
}

CouponsSwitchType.propTypes = {
  discountType: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};
