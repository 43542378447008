import React from "react";
import PropTypes from "prop-types";

function CouponRedeemPrompt({
  redeemCouponHandler,
  setShowRedeemOfferPrompt,
  setCouponCode,
  couponCode,
  isRedeemingCoupons,
}) {
  return (
    <div className="relative w-screen h-screen  flex justify-center items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute h-full w-full bg-black/[.6]"
        onClick={() => setShowRedeemOfferPrompt(false)}
      />
      <div className="absolute z-20 flex flex-col justify-start items-center space-y-5 w-full px-4 pt-[1.7rem] pb-6 bg-white rounded-t-2xl md:rounded-2xl self-end md:self-center lg:max-w-screen-sm">
        <div className="w-full flex flex-row justify-between items-center pb-[1.12rem] px-4 border-b-[0.069rem] border-[#E7E7E7]">
          <h1 className="text-lg text-[#1A1A1A] font-semibold">
            Redeem Online Offer
          </h1>
          <button type="button" onClick={() => setShowRedeemOfferPrompt(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0079 18.5383L4.7651 6.29552C4.34681 5.87722 4.34681 5.18347 4.7651 4.76517C5.1834 4.34688 5.87715 4.34688 6.29545 4.76517L18.5382 17.0079C18.9565 17.4262 18.9565 18.12 18.5382 18.5383C18.1199 18.9566 17.4262 18.9566 17.0079 18.5383Z"
                fill="#1A1A1A"
              />
              <path
                d="M4.76551 18.5383C4.34721 18.12 4.34721 17.4262 4.76551 17.0079L17.0083 4.76517C17.4266 4.34688 18.1203 4.34688 18.5386 4.76517C18.9569 5.18347 18.9569 5.87722 18.5386 6.29552L6.29585 18.5383C5.87756 18.9566 5.1838 18.9566 4.76551 18.5383Z"
                fill="#1A1A1A"
              />
            </svg>
          </button>
        </div>
        <div className="w-full flex flex-col space-y-[0.69rem] items-center px-4 py-4">
          <p className="text-[#1A1A1A] font-normal text-center text-[0.94rem]">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Enter the customer's coupon code below to redeem the online offer
          </p>
          <div className="flex flex-row justify-start items-center w-full py-5 px-4 border border-solid rounded-lg space-x-2">
            <input
              className="h-full w-full outline-none text-[#566370] text-sm font-semibold"
              value={couponCode}
              onBlur={(e) => setCouponCode(e.target.value)}
            />
          </div>
        </div>
        <button
          className="bg-[#4B1D8F] w-full rounded-[0.75rem] text-white text-[1rem] font-bold py-[1.25rem]"
          type="button"
          onClick={redeemCouponHandler}
        >
          {isRedeemingCoupons ? "Redeeming..." : "Redeem"}
        </button>
      </div>
    </div>
  );
}

CouponRedeemPrompt.propTypes = {
  redeemCouponHandler: PropTypes.func.isRequired,
  setShowRedeemOfferPrompt: PropTypes.func.isRequired,
  setCouponCode: PropTypes.func.isRequired,
  couponCode: PropTypes.string.isRequired,
  isRedeemingCoupons: PropTypes.bool.isRequired,
};

export default CouponRedeemPrompt;
