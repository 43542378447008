import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../components/layout/V2/Layout";
import Button from "../../../components/forms/Button";
import LabelledInput from "../../../components/forms/LabelledInput";
import LabelledTextarea from "../../../components/forms/LabelledTextarea";
import { useOffer } from "../../../utils/Contexts/V2/OfferContext";
import { getOfferById } from "../../../utils/requests-v2";

export default function EditOfferDetails() {
  const {
    newOfferHandler,
    isCreatingOffer,
    setNewOffer,
    editOfferHandler,
    newOffer,
  } = useOffer();

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getOfferById(id).then((res) => {
      setNewOffer({
        ...newOffer,
        title: res?.data?.title,
        description: res?.data.description,
        used_ai: res?.data?.used_ai,
        is_active: res?.data?.is_active,
      });
    });
  }, []);

  return (
    <Layout headerTitle="Edit Text Marketing Campaign" header>
      <div className="relative w-full md:max-w-screen-lg h-screen scroll-smooth pt-20 flex flex-col items-center justify-between">
        <div className="px-4 flex flex-col justify-center items-center space-y-4">
          <div className="relative w-[15.56rem] h-1 rounded-lg bg-primary-20">
            <div className="w-[25%] bg-primary-50 h-1 rounded-lg" />
          </div>
          <p className="px-7 font-normal text-base font-sm text-center">
            Grab your customer&apos;s attention. Use an appealing title and
            description
          </p>
          <div className="flex flex-col justify-start items-center space-y-6 w-full">
            <LabelledInput
              id="title"
              placeholder="Enter text Campaign title"
              label="Title"
              name="title"
              value={newOffer.title}
              rules={{ required: true }}
              onChange={newOfferHandler}
            />
            <LabelledTextarea
              label="Description"
              placeholder="Description"
              name="description"
              value={newOffer.description}
              rules={{ required: true, maxLength: 100 }}
              id="description"
              onChange={newOfferHandler}
            />
          </div>
          <div className="w-full flex flex-row justify-start items-center space-x-2">
            <input
              type="checkbox"
              name="add_link"
              onChange={newOfferHandler}
              checked={newOffer?.add_link || false}
              className="accent-primary-0"
            />
            <p>Add your webpage link to this SMS</p>
          </div>
        </div>

        <div className="relative p-4 w-full">
          <Button
            className="font-semibold flex justify-center items-center"
            onClick={() => editOfferHandler(navigate, id)}
          >
            {isCreatingOffer ? `Updating Offer..` : `Continue`}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
