import React, { useState } from "react";
import PropTypes from "prop-types";

function PhotoGallery({ productPhotos, photo, open }) {
  const [photoLimit, setPhotoLimit] = useState(8);

  const loadMorePhotosHandler = () => {
    if (photoLimit < productPhotos.length) {
      setPhotoLimit((prevState) => prevState + 8);
    } else {
      setPhotoLimit(8);
    }
  };

  const selectPhotoHandler = (productPhoto) => {
    photo(productPhoto);
    open(true);
  };

  return (
    <section className="w-full">
      <div className="grid grid-cols-4 gap-2.5">
        {productPhotos.slice(0, photoLimit).map((item) => (
          <button
            type="button"
            className="relative rounded-lg overflow-hidden h-[78px] md:h-[200px] flex grow"
            onClick={() => selectPhotoHandler(item)}
            key={item.id}
          >
            <img
              alt={item.title || item.product_name}
              className="block object-cover w-full h-full"
              src={item.business_photo || item.photo_url}
              title={item.title || item.product_name}
            />
          </button>
        ))}
      </div>
      {productPhotos.length > 8 && (
        <button
          type="button"
          onClick={() => loadMorePhotosHandler()}
          className="text-purple-50 text-sm font-normal h-4"
        >
          {photoLimit >= productPhotos.length ? "See less" : "See more"}
        </button>
      )}
    </section>
  );
}

PhotoGallery.defaultProps = {
  productPhotos: [],
};

PhotoGallery.propTypes = {
  productPhotos: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  photo: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
};
export default PhotoGallery;
