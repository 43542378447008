import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  confirmMpesaPayment,
  createMpesaIntent,
  mpesaSDKPrompt,
} from "../../utils/requests-v2";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import { generatePaymentData } from "./mpesa-payment.util";
import { useWalletContext } from "../../utils/Contexts/V2/WalletContext";
import { useSubscriptionContext } from "../../utils/Contexts/V2/SubscriptionContext";

const useMpesaPayment = () => {
  const navigate = useNavigate();
  const { paymentSubscriptionData } = useSubscriptionContext();
  const { user } = useAuth();
  const { topUpAmount: paymentAmount, paymentReason } = useWalletContext();
  const [mpesaPayMethod, setMpesaPayMethod] = useState({
    phone_number: false,
    pay_bill: false,
  });
  const [showSDKPrompt, setShowSDKPrompt] = useState(false);
  const [loadingIntent, setLoadingIntent] = useState(false);
  const [confirmingPayment, setConfirmingPayment] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentData, setPaymentData] = useState({
    payment_reason: paymentReason,
    amount: paymentAmount,
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [inputErrors, setInputErrors] = useState({
    phoneNumber: "",
    refNumber: "",
  });

  const mpesaPayMethodHandler = (e) => {
    const { id } = e.target;
    if (id === "phone_number") {
      setMpesaPayMethod({ phone_number: true, pay_bill: false });
    } else {
      setMpesaPayMethod({ phone_number: false, pay_bill: true });
    }
  };

  const inputHandler = (e) => {
    const { value, id } = e.target;
    if (id === "phoneNumber") {
      setPhoneNumber(value);
    } else if (id === "refNumber") {
      setRefNumber(value);
    }
  };

  const validateInput = (e) => {
    const { value, id } = e.target;

    if (!value) {
      setInputErrors({ ...inputErrors, [id]: "This field is required" });
    } else {
      setInputErrors({ ...inputErrors, [id]: "" });
    }
  };

  const pushSDKPaymentPrompt = () => {
    if (!phoneNumber) {
      toast.error("Please enter your phone number");
      return;
    }
    const data = {
      phone_number: `254${
        phoneNumber.startsWith("0") ? phoneNumber.slice(1) : phoneNumber
      }`,
      payment_intent_ref: paymentIntent,
    };
    setConfirmingPayment(true);
    mpesaSDKPrompt(user?.id, data)
      .then((res) => {
        if (res?.data?.info === "Transaction Successful") {
          setShowSDKPrompt(true);
        } else {
          toast.error("Failed to send payment prompt");
        }
      })
      .catch((e) => {
        const message = e?.response?.data.detail
          ? JSON.stringify(e.response.data.detail)
          : "An error occurred";
        toast.error(message);
      })
      .finally(() => {
        setConfirmingPayment(false);
      });
  };

  const confirmPaymentHandler = () => {
    setConfirmingPayment(true);
    confirmMpesaPayment(paymentIntent, user?.id)
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          toast.success("Payment successful");
          navigate(`/payments/success?for=CREDITS`);
        } else {
          toast.error("Payment status is pending. Please try again");
        }
      })
      .catch((e) => {
        toast.error("Payment failed");
        console.log("An error occurred while confirming payment", e);
      })
      .finally(() => {
        setConfirmingPayment(false);
      });
  };

  useEffect(() => {
    setLoadingIntent(true);
    const data =
      paymentReason === "SUBSCRIPTION"
        ? { ...paymentSubscriptionData, payment_reason: paymentReason }
        : generatePaymentData(paymentData);
    // Fetch the payment intent from the server
    createMpesaIntent(data, user?.id)
      .then((res) => {
        setPaymentIntent(res.data.reference);
        setLoadingIntent(false);
      })
      .catch(() => {
        setLoadingIntent(false);
      });
  }, []);

  return {
    showSDKPrompt,
    loadingIntent,
    confirmingPayment,
    setPaymentData,
    pushSDKPaymentPrompt,
    confirmPaymentHandler,
    paymentAmount,
    inputErrors,
    inputHandler,
    mpesaPayMethod,
    setMpesaPayMethod,
    mpesaPayMethodHandler,
    phoneNumber,
    refNumber,
    user,
    setShowSDKPrompt,
    validateInput,
    paymentIntent,
  };
};

export default useMpesaPayment;
