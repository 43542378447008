/* eslint-disable camelcase */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import {
  checkPaybillPaymentStatus,
  makeMpesaPayment,
  topUpWallet,
} from "../../requests";
import {
  confirmPaymentViaAirtime,
  creditWallet,
  getWallet,
  getWalletTransactionDetails,
  payWithAirtime,
  payWithFlutterwave,
  checkFlutterwavePaymentStatus,
  checkPromoCode,
} from "../../requests-v2";
import { usePaymentContext } from "./PaymentContext";
import { useAuth } from "./AuthenticationContext";
import { useAppContext } from "../AppContext";

const WalletContext = createContext(null);

function useWalletContext() {
  return useContext(WalletContext);
}

const ID_IDENTIFIER = {
  tappi: "TAP",
  thryve: "EN",
};

function WalletProvider({ children }) {
  const { user } = useAuth();
  const country = user?.country.name;
  const currency = user?.country?.currency_code;
  const userID = user?.id;
  const phoneNumber = user?.phone_number;
  const { appVendor } = useAppContext();

  const {
    mpesaPayPhoneNumber,
    selectedPayment,
    msisdn,
    setInputError,
    setFlutterwavePaymentDetails,
    flutterwavePaymentDetails,
    promoCode,
    referenceNumber,
    setSelectedPayment,
  } = usePaymentContext();
  const [isLoadingWalletData, setIsLoadingWalletData] = useState(false);
  const [isCheckingAirtimePaymentStatus, setIsCheckingAirtimePaymentStatus] =
    useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [airtimeConfirmationPrompt, setAirtimeConfirmationPrompt] =
    useState(false);
  const [walletDetails, setWalletDetails] = useState(null);
  const [walletTransactionHistory, setWalletTransactionHistory] =
    useState(null);
  const [topUpAmount, setTopUpAmount] = useState(
    localStorage.getItem("TOP_UP_AMOUNT") || 0
  );
  const [paymentReason, setPaymentReason] = useState(null);
  const [showWalletTopUp, setShowWalletTop] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const paymentData = {
    phone_number: mpesaPayPhoneNumber,
    amount: topUpAmount.toString() || 0,
  };
  const flutterwavePaymentData = {
    amount: topUpAmount.toString() || 0,
  };

  const topUpAmountHandler = (e) => {
    if (e.target.value > 0) {
      setInputError(false);
      setTopUpAmount(e.target.value);
    } else {
      setTopUpAmount(0);
      setInputError(true);
    }
  };

  const paymentNavigationHandler = (callback) => {
    switch (window.location.pathname.includes("subscription")) {
      case true:
        if (selectedPayment.mpesa) {
          callback("/payments/mpesa");
        } else {
          callback("/payments/promo");
        }
        break;
      default:
        if (selectedPayment.mpesa) {
          callback("/wallet/payment/mpesa");
        } else {
          callback("/wallet/payment/promo_code");
        }
        break;
    }
  };

  // A helper function to throw an error when an undesired response is gotten from a call
  const errorHandler = (e) => {
    throw e;
  };

  // Subscription Payment with Mpesa
  const mpesaPay = async (callback) => {
    if (mpesaPayPhoneNumber && mpesaPayPhoneNumber.length === 13) {
      setIsLoadingWalletData(true);
      makeMpesaPayment(paymentData)
        .then((res) => {
          if (res.data.status === 400) {
            setErrorMessage("Transaction Unsuccessful");
            return errorHandler();
          }
          return topUpWallet(paymentData);
        })
        .then((res) => {
          setIsLoadingWalletData(false);
          callback("/wallet/status");
          Promise.resolve(res);
        })
        .catch((error) => {
          setShowError(true);
          setIsLoadingWalletData(false);
          Promise.reject(error);
          setTimeout(() => setShowError(false), 5000);
        });
    } else {
      setInputError(true);
    }
  };

  // Subscription Payment with Flutterwave
  const flutterwavePay = async () => {
    setIsProcessingPayment(true);
    payWithFlutterwave(flutterwavePaymentData)
      .then((res) => {
        if (res.data.status === "failed") {
          setIsProcessingPayment(false);
          toast.error("Service currently unavailable");
          return errorHandler();
        }
        setIsProcessingPayment(false);
        setFlutterwavePaymentDetails(res.data);
        return Promise.resolve(res);
      })
      .catch((error) => {
        setIsProcessingPayment(false);
        toast.error("Something went wrong");
        Promise.reject(error);
      });
  };

  // Confirm status Subscription Payment with Flutterwave
  const confirmFlutterwavePaymentStatus = async (callback) => {
    const payRef = {
      transaction_id: flutterwavePaymentDetails.transaction_id,
    };

    const topWalletData = {
      amount: topUpAmount,
      type: "top-up",
      origin: walletDetails.platform,
    };

    setIsProcessingPayment(true);
    checkFlutterwavePaymentStatus(payRef)
      .then((res) => {
        if (res.data.status !== "successful") {
          setIsProcessingPayment(false);
          // eslint-disable-next-line no-throw-literal
          throw "pending";
        }
        return creditWallet(`EN-${phoneNumber}`, topWalletData);
      })
      .then((res) => {
        setIsProcessingPayment(true);
        callback("/wallet/status");
        toast.success("Top up successful");
        return Promise.resolve(res);
      })
      .catch((error) => {
        setIsProcessingPayment(false);
        if (error === "pending") {
          toast.error("Payment not yet received.");
          return Promise.reject(error);
        }
        toast.error(
          error.response?.data?.detail[0]?.msg || "Something went wrong"
        );
        return Promise.reject(error);
      });
  };

  // Confirm status Wallet top up Payment with Paybill
  const confirmPaybillPaymentStatus = async (callback) => {
    const data = {
      reference_number: referenceNumber,
      amount: topUpAmount,
    };
    const topWalletData = {
      phone_number: msisdn,
      amount: topUpAmount,
    };
    if (data.reference_number && data.reference_number.length === 10) {
      setIsLoadingWalletData(true);
      setInputError(false);
      checkPaybillPaymentStatus(data)
        .then((res) => {
          if (res.data.status !== 200) {
            setErrorMessage("Payment not yet received.");
            return errorHandler();
          }
          return topUpWallet(topWalletData);
        })
        .then((res) => {
          setIsLoadingWalletData(false);
          callback("/wallet/status");
          Promise.resolve(res);
        })
        .catch((error) => {
          setShowError(true);
          setIsLoadingWalletData(false);
          Promise.reject(error);
          setTimeout(() => setShowError(false), 5000);
        });
    } else {
      setInputError(true);
    }
  };

  // Manages Payment using promo code
  const promoCodePay = async (callback) => {
    const topWalletData = {
      phone_number: msisdn,
      amount: topUpAmount,
    };
    setIsLoadingWalletData(true);
    if (promoCode) {
      checkPromoCode({ code: promoCode })
        .then(() => topUpWallet(topWalletData))
        .then((res) => {
          setIsLoadingWalletData(false);
          toast.success("Top up successful");
          callback("/wallet/status");
          Promise.resolve(res);
        })
        .catch((res) => {
          setIsLoadingWalletData(false);
          if (res?.response?.status === 404) {
            toast.error(res.response?.data?.detail || "Something went wrong");
          } else {
            toast.error(
              res.response?.data?.detail[0]?.msg || "Something went wrong"
            );
          }
          Promise.reject(res);
        });
    } else {
      setIsLoadingWalletData(false);
      setInputError(true);
    }
  };

  const airtimePay = async () => {
    setIsProcessingPayment(true);
    payWithAirtime({ amount: topUpAmount })
      .then((res) => {
        setTransactionId(res.data.data.transactionId);
        setAirtimeConfirmationPrompt(true);
        setIsProcessingPayment(false);
      })
      .catch((res) => {
        setIsProcessingPayment(false);
        setAirtimeConfirmationPrompt(false);
        toast.error(
          res.response?.data?.detail[0]?.msg || "Something went wrong"
        );
      });
  };

  const airtimePayConfirmation = async (callback) => {
    const topWalletData = {
      amount: topUpAmount,
      type: "top-up",
      origin: walletDetails.platform,
    };
    setIsCheckingAirtimePaymentStatus(true);
    confirmPaymentViaAirtime({ transaction_id: transactionId })
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.data.status === "success") {
          return creditWallet(`EN-${phoneNumber}`, topWalletData);
        }
        setIsCheckingAirtimePaymentStatus(false);
        setErrorMessage("Payment is pending");
        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
        throw Error("Payment is pending");
      })
      .then((res) => {
        setIsCheckingAirtimePaymentStatus(false);
        setIsLoadingWalletData(false);
        callback("/wallet/status");
        Promise.resolve(res);
      })
      .catch((error) => {
        setIsCheckingAirtimePaymentStatus(false);
        setShowError(true);
        setIsLoadingWalletData(false);
        Promise.reject(error);
        setTimeout(() => setShowError(false), 5000);
      });
  };

  useEffect(() => {
    setIsLoadingWalletData(true);
    if (userID) {
      getWallet(`${ID_IDENTIFIER[appVendor]}-${phoneNumber}`)
        .then((res) => {
          setIsLoadingWalletData(false);
          setWalletDetails(res.data);
        })
        .catch(() => {
          setIsLoadingWalletData(false);
          setWalletDetails(null);
        });
      getWalletTransactionDetails(`${ID_IDENTIFIER[appVendor]}-${phoneNumber}`)
        .then((res) => {
          setWalletTransactionHistory(res.data);
          setIsLoadingWalletData(false);
        })
        .catch(() => {
          setWalletTransactionHistory(null);
        });
    } else {
      setWalletDetails([]);
      setWalletTransactionHistory([]);
    }

    if (country === "Nigeria") {
      setSelectedPayment({
        promoCode: false,
        mpesa: false,
        flutterwave: false,
        airtime: true,
      });
    }
  }, [userID, user]);

  useEffect(() => {
    localStorage.setItem("TOP_UP_AMOUNT", topUpAmount);
  }, [topUpAmount]);

  const value = useMemo(() => ({
    isLoadingWalletData,
    walletDetails,
    walletTransactionHistory,
    currency,
    topUpAmountHandler,
    showWalletTopUp,
    setShowWalletTop,
    topUpAmount,
    errorMessage,
    mpesaPay,
    showError,
    isProcessingPayment,
    paymentNavigationHandler,
    flutterwavePay,
    confirmFlutterwavePaymentStatus,
    promoCodePay,
    confirmPaybillPaymentStatus,
    airtimeConfirmationPrompt,
    airtimePay,
    airtimePayConfirmation,
    setAirtimeConfirmationPrompt,
    isCheckingAirtimePaymentStatus,
    ID_IDENTIFIER,
    paymentReason,
    setPaymentReason,
    setTopUpAmount,
  }));

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  );
}

export { useWalletContext, WalletProvider };

WalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
