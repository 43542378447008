import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Transition } from "@headlessui/react";
import LabelledInput from "../forms/LabelledInput";
import Button from "../forms/Button";
import { useContact } from "../../utils/Contexts/V2/ContactContext";

export default function ImportCustomerPrompt({ setShowPrompt }) {
  const {
    contactDataHandler,
    isLoading,
    createContact,
    showContactErrorPopUp,
    errorMessage,
    formErrors,
  } = useContact();
  const navigate = useNavigate();

  const uploadContactHandler = () => {
    setShowPrompt(false);
    navigate("/customers");
  };
  return (
    <div className="relative w-screen h-screen  flex justify-center items-center">
      <Transition
        className="fixed left-0 right-0 -bottom-12 z-20 w-full flex justify-center items-center shadow-xl mt-20 "
        show={showContactErrorPopUp}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <p className="py-2.5 px-4 text-red-50 text-sm font-bold w-64 text-center bg-white rounded-[6.25rem] mt-72">
          {errorMessage}
        </p>
      </Transition>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute h-full w-full bg-black/[.6]"
        onClick={() => setShowPrompt(false)}
      />
      <div className="absolute z-20 flex flex-col justify-start items-center space-y-5 w-full px-4 pt-[1.7rem] pb-6 bg-white rounded-t-2xl md:rounded-2xl self-end md:self-center lg:max-w-screen-sm">
        <div className="w-full flex flex-row justify-between items-center pb-[1.12rem] px-4 border-b-[0.069rem] border-[#E7E7E7]">
          <div />
          <h1 className="text-lg text-[#1A1A1A] font-semibold">
            Add new customer
          </h1>
          <button type="button" onClick={() => setShowPrompt(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0079 18.5383L4.7651 6.29552C4.34681 5.87722 4.34681 5.18347 4.7651 4.76517C5.1834 4.34688 5.87715 4.34688 6.29545 4.76517L18.5382 17.0079C18.9565 17.4262 18.9565 18.12 18.5382 18.5383C18.1199 18.9566 17.4262 18.9566 17.0079 18.5383Z"
                fill="#1A1A1A"
              />
              <path
                d="M4.76551 18.5383C4.34721 18.12 4.34721 17.4262 4.76551 17.0079L17.0083 4.76517C17.4266 4.34688 18.1203 4.34688 18.5386 4.76517C18.9569 5.18347 18.9569 5.87722 18.5386 6.29552L6.29585 18.5383C5.87756 18.9566 5.1838 18.9566 4.76551 18.5383Z"
                fill="#1A1A1A"
              />
            </svg>
          </button>
        </div>
        <div className="py-5 px-4 flex flex-col justify-start items space-y-[3.88rem]">
          <div className="flex-col justify-start items space-y-5">
            <p className="text-center text-[#6D7789]">
              Enter your customer’s details and add it to your customers list.{" "}
            </p>
            <div>
              <LabelledInput
                id="customerNameInput"
                placeholder="Customer Name"
                label="Customer Name"
                name="full_name"
                rules={{ required: true }}
                onBlur={contactDataHandler}
              />
              {formErrors.full_name && (
                <p className="text-xs text-red-500">{formErrors.full_name}</p>
              )}
            </div>
            <div>
              <LabelledInput
                id="phoneNumberInput"
                placeholder="Phone Number"
                label="Phone Number"
                name="phone_number"
                type="number"
                rules={{ required: true }}
                onBlur={contactDataHandler}
              />
              {formErrors.phone_number && (
                <p className="text-xs text-red-500">
                  {formErrors.phone_number}
                </p>
              )}
            </div>
          </div>
          <Button
            onClick={() => createContact(uploadContactHandler)}
            className="font-semibold"
          >
            {isLoading ? "Uploading..." : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
}

ImportCustomerPrompt.propTypes = {
  setShowPrompt: PropTypes.func.isRequired,
};
