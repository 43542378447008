import React from "react";
import PropTypes from "prop-types";

export default function CouponOptions({
  status,
  isPaused,
  setShowCouponOptions,
  setShowDeleteConfirmationPrompt,
  setShowPauseConfirmationPrompt,
}) {
  const deleteActionHandler = () => {
    setShowCouponOptions(false);
    setShowDeleteConfirmationPrompt(true);
  };

  const pauseActionHandler = () => {
    setShowCouponOptions(false);
    setShowPauseConfirmationPrompt(true);
  };

  return (
    <div className="relative h-screen w-full flex flex-col justify-center items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="absolute h-screen w-full bg-blur" />
      <div className="absolute w-[80vw] bg-white pt-[1.62rem] px-5 py-3 lg:max-w-screen-sm">
        <h1 className="pb-6 font-semibold text-base">Coupon Options</h1>
        <div className="flex flex-col justify-start items-start space-y-7 pb-4">
          {status === "Live" && (
            <button
              type="button"
              className="text-sm"
              onClick={pauseActionHandler}
            >
              {isPaused ? "Unpause" : "Pause"}
            </button>
          )}
          <button
            type="button"
            className="text-sm"
            onClick={deleteActionHandler}
          >
            Delete
          </button>
          <button
            type="button"
            className="text-sm"
            onClick={() => setShowCouponOptions(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

CouponOptions.propTypes = {
  status: PropTypes.string.isRequired,
  isPaused: PropTypes.bool.isRequired,
  setShowDeleteConfirmationPrompt: PropTypes.func.isRequired,
  setShowCouponOptions: PropTypes.func.isRequired,
  setShowPauseConfirmationPrompt: PropTypes.func.isRequired,
};
