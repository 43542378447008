/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

import axios from "axios";
import moment from "moment";
import {
  customAxios,
  RESOLVED_BASE_URL,
} from "./Contexts/V2/authenticationService";
import { BRIDGE_ACCESS_KEY, BRIDGE_URL, API_URL } from "./requests";

const API_PATHS = {
  registerMerchant: "/merchants",
  loginMerchant: "/token",
  getMerchantOTP: "/otp",
  getMerchantDetails(id) {
    return `/merchants/${id}`;
  },
  getMerchantOperationHours: "/operation-hours",
  updateMerchantOperationHours(id) {
    return `/operation-hours/${id}`;
  },
  deleteMerchantOperationHours(id) {
    return `/operation-hours/${id}`;
  },
  uploadMerchantBusinessPhotos(merchant_id) {
    return `/merchants/profile-photos/${merchant_id}`;
  },
  updateBusinessPhotos(merchant_id) {
    return `/merchants/profile-photos/${merchant_id}`;
  },
  updateMerchantDetails: "/merchants",
  contacts: "/contacts",
  offers: "/offers",
  getMerchantFacebookAds: "/ads/v2",
  createFacebookAd: "/ads",
  updateFacebookAd(id) {
    return `/ads/update-ad/${id}`;
  },
  uploadFacebookAdImage(id) {
    return `/ads/ad-image/${id}`;
  },
  updateFacebookAdImage(id) {
    return `/ads/update-image/${id}`;
  },
  deleteFacebookAdImage(id) {
    return `/ads/delete-image/${id}`;
  },
  getFacebookAdDetail(id) {
    return `/ads/v2/${id}`;
  },
  createMerchantProductPhotos() {
    // function toQueryParams(obj) {
    //   return Object.entries(obj)
    //     .filter(
    //       ([key, value]) => value !== null && value !== "" && key !== "images"
    //     )
    //     .map(
    //       ([key, value]) =>
    //         `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    //     )
    //     .join("&");
    // }
    //
    // const query = toQueryParams(data);
    // return `/products/v2/upload${query ? `?${query}` : ""}`;
    return `/products/v2/upload`;
  },
  createMerchantProductCategory: "/products/categories",
  getMerchantProductCategory: "/products/categories?page=1&size=100",
  getMerchantProductPhotos(query) {
    return `/products/v2${query ? `?${query}` : ""}`;
  },
  updateMerchantProductPhoto(id, queryObj) {
    function toQueryParams(obj) {
      return Object.entries(obj)
        .filter(([key, value]) => value !== null && value !== "")
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
    }
    const query = toQueryParams(queryObj);
    return `/products/v2/${id}${query ? `?${query}` : ""}`;
  },
  deleteMerchantProductPhotos(id) {
    return `/products/${id}`;
  },
  getIndustries: "/industries",
  paymentViaAirtime: "/payments/mtn-nigeria-airtime",
  confirmPaymentViaAirtime: "/payments/confirm-airtime-payment",
  getAdsFeed: "/ads/ads-feed",
  readWalletDetails(user_id) {
    return `/wallets/${user_id}`;
  },
  readWalletTransactions(user_id) {
    return `/wallets/${user_id}/transactions`;
  },
  creditWallet(user_id) {
    return `/wallets/credit/${user_id}`;
  },
  debitWallet(user_id) {
    return `/wallets/debit/${user_id}`;
  },
  topUpBulkSMS(user_id) {
    return `/wallets/topup-bulksms/${user_id}`;
  },
  debitBulkSMS(user_id) {
    return `/wallets/debit-bulksms/${user_id}`;
  },
  getCountryList: "/countries",
  deleteAccount: "/merchants/delete",
  paymentInfo: "/payment-info",
  collectPayment: "/request-paystack",
  checkPromoCode(code) {
    return `/promo_codes/${code}`;
  },
  bankList: "/request-paystack",
  paymentWithFlutterwave: "/payments/flutterwave",
  confirmPaymentViaFlutterwave: "/payments/confirm-flutterwave-payment",
  getAdZones(params) {
    return `/ads/ad-zones/?${params}`;
  },
  uploadDocument(name) {
    return `documents?name=${name}`;
  },
  getDocument: `/documents`,
  getSubscriptionHistory: "/subscriptions/subscription-history",
  getSubscriptionPackages: "/subscriptions",
  updateSubscriptionPackages: "/subscriptions/update-subscription-package",
  socialLinks: "/social_links/",
  createSocialLinks: "/social_links",
  generateAdDescriptionWithAi: "/ai/ad_copy/v2",
  sendNotification: "/notifications",
  getFacebookAdStatistics(id) {
    return `/adsinsights/${id}`;
  },
  completeFacebookSetup: "/facebook/complete-setup",
  listConnectedFacebookPages: "/facebook/pages",
  approveFacebookAd(adId) {
    return `/ads/approved/${adId}`;
  },
  immediatelyPostFacebookAd: "/facebook/post-to-fb?password=BaCkEnD",
  forgotPassword: "/users/forgot-password",
  resetForgottenPassword: "/users/reset-password",
  listAccessibleMerchantAccounts: "/merchants",
  searchAccessibleMerchantAccounts: "/merchants/search",
  switchMerchantAccount: "/token/merchant",
  createIntent(merchantId) {
    return `/payments/api/payment-intents?merchant_id=${merchantId}`;
  },
  confirmPayment(intent) {
    return `/payments/api/payment-intents/${intent}`;
  },
  mpesaSDKPrompt(merchantId) {
    return `/payments/api/v1/mpesa-stk-push?merchant_id=${merchantId}`;
  },
  aiTextMarketing: "/ai/text-marketing",
  uploadPhotoMiniApp: "/products/miniapps/upload-photo",
  updateProductDetailsMiniApp: (productId) =>
    `/products/v2/miniapps/${productId}`,
  getGoogleAnalyticsData: "/google-analytics",
  coupons: "/coupon-programs",
};

/**
 * Register a merchant account
 * @param {*} data
 * @returns AciosResponse<any>
 */
export async function registerMerchant(data) {
  try {
    return await customAxios.post(API_PATHS.registerMerchant, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * This methods attempts to log a user in and returns a JWT token
 * @param { {username: string, password: string} } data - The phone number and OTP of the user who is logging in
 * @returns AxiosResponse<any>
 */
export async function loginMerchant(data) {
  try {
    return await customAxios
      .post(API_PATHS.loginMerchant, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMerchantOTP({ phoneNumber }) {
  try {
    return await customAxios
      .post(
        API_PATHS.getMerchantOTP,
        { phone_number: phoneNumber, client: "APP" },
        {
          withCredentials: false,
        }
      )
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCurrentMerchant(id) {
  try {
    return await customAxios
      .get(`${API_PATHS.getMerchantDetails(id)}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Create a new contacts
 * @param {{ full_name: String, phone_number: String }} data - Data for creating new contact
 */
export async function createNewContact(data) {
  try {
    return await customAxios.post(API_PATHS.contacts, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllContacts(offset) {
  try {
    return await customAxios.get(
      `${API_PATHS.contacts}?offset=${offset}&limit=1000`
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function searchContact(query) {
  try {
    return await customAxios.get(
      `${API_PATHS.contacts}/search?search_string=${query}`
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Delete a new contacts
 * @param {string} id
 */
export async function deleteContact(id) {
  try {
    return await customAxios.delete(`${API_PATHS.contacts}/${id}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getIndustries() {
  try {
    return await customAxios
      .get(`${API_PATHS.getIndustries}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get Merchant Operation Hours
export async function getMerchantOperationHours() {
  try {
    return customAxios
      .get(`${API_PATHS.getMerchantOperationHours}`)
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Create Merchant Operation Hours

export async function createMerchantOperationHours({ data }) {
  try {
    return customAxios
      .post(`${API_PATHS.getMerchantOperationHours}`, data)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Update Merchant Operation Hours

export async function updateMerchantOperationHours({ id, data }) {
  try {
    return customAxios
      .put(`${API_PATHS.updateMerchantOperationHours(id)}`, data)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Delete merchant operation hours

export async function deleteMerchantOperationHours({ id }) {
  try {
    return await customAxios
      .delete(`${API_PATHS.deleteMerchantOperationHours(id)}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Upload Business Photos
/**
 * Upload business photos of a merchant
 * @param {{ coverPhoto: File, profilePhoto: File, merchantID: integer }} param0 - Images to be uploaded for the merchant
 * @returns AxiosResponse<any>
 */
export async function uploadMerchantBusinessPhotos({
  coverPhoto,
  profilePhoto,
  merchantID,
}) {
  const data = {
    cover_photo: coverPhoto,
    profile_photo: profilePhoto,
  };
  try {
    return await customAxios
      .post(API_PATHS.uploadMerchantBusinessPhotos(merchantID), data, {
        withCredentials: false,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Update Business Photos
/**
 * Update the business photos of a merchant
 * @param {{ cover_photo: File, profile_photo: File, merchantID: integer }} param0 - Image files for the merchant
 * @returns AxiosResponse<any>
 */
export async function updateBusinessPhotos({
  cover_photo,
  profile_photo,
  merchantID,
}) {
  const data = {
    ...(cover_photo ? { cover_photo } : {}),
    ...(profile_photo ? { profile_photo } : {}),
  };

  try {
    return await customAxios
      .post(`${API_PATHS.updateBusinessPhotos(merchantID)}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Update Merchant Details
/**
 * Update general merchant details
 * @param {int} merchantId - merchant id to update
 * @param {object} data - data to update
 * @returns AxiosResponse<any>
 */
export async function updateMerchantDetails(merchantId, data) {
  try {
    return await customAxios
      .put(`${API_PATHS.updateMerchantDetails}`, data, {
        withCredentials: false,
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMerchantFacebookAds() {
  try {
    return await customAxios
      .get(API_PATHS.getMerchantFacebookAds)
      .then((response) => {
        const ads = response.data.items;

        ads.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

        return ads;
      });
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createFacebookAd({ data }) {
  try {
    return customAxios
      .post(API_PATHS.createFacebookAd, data)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateFacebookAd({ data, id }) {
  try {
    return customAxios
      .put(API_PATHS.updateFacebookAd(id), data)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function uploadFacebookAdImage({ image, id }) {
  const form = new FormData();

  form.append("file", image);
  try {
    return customAxios
      .post(API_PATHS.uploadFacebookAdImage(id), form)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get single facebook ad details
export async function getFacebookAdDetail({ adId }) {
  try {
    return await customAxios
      .get(`${API_PATHS.getFacebookAdDetail(adId)}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Delete Facebook Ad Image

export async function deleteFacebookAdImage({ imageId }) {
  try {
    return customAxios
      .delete(`${API_PATHS.deleteFacebookAdImage(imageId)}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Update Facebook Ad Image

export async function updateFacebookAdImage({ imageId, image }) {
  try {
    return await customAxios
      .put(
        `${API_PATHS.updateFacebookAdImage(imageId)}`,
        { file: image },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Create Merchant Product Photos
export async function createMerchantProductPhotos(data) {
  try {
    return await customAxios
      .post(`${API_PATHS.createMerchantProductPhotos()}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Create Merchant Product Category
/**
 * Creates photos for a merchant product category  *
 * @param categoryName - name of category.
 * @returns {Promise} A promise that resolves to the response data if the request succeeds,
 *                    or rejects with an error if the request fails.
 */

export async function createMerchantProductCategory(categoryName) {
  try {
    return await customAxios
      .post(`${API_PATHS.createMerchantProductCategory}`, {
        category_name: categoryName,
      })
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get merchant product photos
/**
 * Get list of product photos for a merchant based on the provided query object.
 * @async
 * @function getMerchantProductPhotos
 * @param { {query: URLSearchParams }} object - An object containing query parameters for the request.
 * @returns {Promise<Object>} A promise that resolves with the retrieved product photos data.
 */
export async function getMerchantProductPhotos({ query }) {
  try {
    return await customAxios
      .get(`${API_PATHS.getMerchantProductPhotos(query.toString())}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get merchant product categories
/**
 * Get list of product photos for a merchant based on the provided query object.
 * @async
 * @function getMerchantProductCategories
 * @returns {Promise<Object>} A promise that resolves with the retrieved product photos data.
 */
export async function getMerchantProductCategories() {
  try {
    return await customAxios
      .get(`${API_PATHS.getMerchantProductCategory}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Update merchant photos
/**
 * Update product photo by id.
 * @async
 * @function updateMerchantProductPhoto
 * @returns {Promise<Object>} A promise that resolves with the retrieved product photos data.
 */
export async function updateMerchantProductPhoto(
  photoId,
  updatedData,
  businessPhoto
) {
  try {
    const data = businessPhoto ? { image: businessPhoto } : {};
    return await customAxios
      .put(
        `${API_PATHS.updateMerchantProductPhoto(photoId, updatedData)}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Deletes the product photos of a merchant item with the given ID.
 * @async
 * @param {Object} options - The options for the function.
 * @param {number} options.id - The ID of the merchant item to delete photos for.
 * @returns {Promise} A promise that resolves with the data returned by the server on success, or rejects with an error on failure.
 */
export async function deleteMerchantProductPhotos({ id }) {
  try {
    return await customAxios
      .delete(`${API_PATHS.deleteMerchantProductPhotos(id)}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Get Wallet Detail
 * @param {string} user_id
 */
export async function getWallet(user_id) {
  try {
    return await customAxios.get(`${API_PATHS.readWalletDetails(user_id)}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Get Wallet Transaction details
 * @param {string} user_id
 */
export async function getWalletTransactionDetails(user_id) {
  try {
    return await customAxios.get(
      `${API_PATHS.readWalletTransactions(user_id)}`
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Credit Wallet
 * @param {string} user_id
 * @param {{ amount: int, type: string, origin: string}} data
 */
export async function creditWallet(user_id, data) {
  try {
    return await customAxios.put(`${API_PATHS.creditWallet(user_id)}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Debit Wallet
 * @param {string} user_id
 * @param {{ amount: int, type: string }} data
 */
export async function debitWallet(user_id, data) {
  try {
    return await customAxios.put(`${API_PATHS.debitWallet(user_id)}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Debit Bulk SMS
 * @param {string} user_id
 * @param {{ quantity: int}} data
 */
export async function debitBulkSMS(user_id, data) {
  try {
    return await customAxios.put(`${API_PATHS.debitBulkSMS(user_id)}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * TopUp Bulk SMS
 * @param {string} user_id
 * @param {{ bulk_sms_count: int}} data
 */
export async function topUpBulkSMS(user_id, data) {
  try {
    return await customAxios.put(`${API_PATHS.topUpBulkSMS(user_id)}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Pay with airtime
 * @param {{amount: (string|number)}} data
 */
export async function payWithAirtime(data) {
  try {
    return await customAxios.post(`${API_PATHS.paymentViaAirtime}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Confirm Airtime Payment
 * @param {{transaction_id: number}} data
 */
export async function confirmPaymentViaAirtime(data) {
  try {
    return await customAxios.post(
      `${API_PATHS.confirmPaymentViaAirtime}`,
      data
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMerchantReviews(joinCode, limit = 8, offset = 0) {
  return axios
    .get(
      `${API_URL}/reviews?join_code=${joinCode}&offset=${offset}&limit=${limit}&show_number=true`
    )
    .then((res) => {
      // This is to solve the dependency on Ajua for reviews when Bridge goes down
      if (res.status !== 200 || res.status === 400) {
        return {};
      }
      return res.data.data;
    });
}

export async function replyToReview({ reviewId, data }) {
  try {
    return axios.post(
      `${BRIDGE_URL}/ext/surveys/responses/${reviewId}/reply`,
      data,
      {
        withCredentials: false,
        headers: {
          "x-access-key": BRIDGE_ACCESS_KEY,
        },
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function replyToReviewKE({ reviewId, data }) {
  try {
    return axios.post(`${API_URL}/reviews/${reviewId}/reply`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get ads feed

export async function getAdsFeed() {
  try {
    return customAxios.get(`${API_PATHS.getAdsFeed}`).then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Create a new offer
 * @param {{ title: string, description: string, is_active: boolean, start_date: string, end_date: string}} data- Data for creating new contact
 */
export async function createOffer(data) {
  try {
    return await customAxios.post(API_PATHS.offers, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Get all offers
 */
export async function getAllOffers() {
  try {
    return await customAxios.get(`${API_PATHS.offers}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * post a new offer
 * @param {{ contacts: array }} data Data for creating new contact
 * @param {string} id
 */
export async function postOffer(id, data) {
  try {
    return await customAxios.post(`${API_PATHS.offers}/${id}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function postBulkOffer(id, sendLink) {
  try {
    return await customAxios.post(
      `${API_PATHS.offers}/${id}/all-contacts?send_link=${sendLink}`
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * edit offer
 * @param {{title: string, description: string, is_active: boolean}} data - Data for creating new contact
 * @param {string} id
 */
export async function editOffer(id, data) {
  try {
    return await customAxios.put(`${API_PATHS.offers}/${id}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getOfferById(id) {
  try {
    return await customAxios.get(`${API_PATHS.offers}/${id}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * delete offer
 * @param {string} id
 */
export async function deleteOffer(id) {
  try {
    return await customAxios.delete(`${API_PATHS.offers}/${id}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Get offer contacts
 * @param {string} id
 */
export async function getOfferContacts(id) {
  try {
    return await customAxios.get(`${API_PATHS.offers}/${id}/contacts`, {
      validateStatus: (status) => status === 200 || status === 404,
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

// Get countries

export async function getCountryList() {
  try {
    return customAxios
      .get(`${API_PATHS.getCountryList}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteAccount() {
  try {
    return customAxios
      .delete(`${API_PATHS.deleteAccount}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Payment Info
/**
 * save payment info
 * @param {{bank_id: string, bank_name: string, account_number: string}} data - Data for creating new contact
 */
export async function savePaymentInfo(data) {
  try {
    return await customAxios.put(`${API_PATHS.paymentInfo}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * get payment info
 */
export async function getPaymentInfo() {
  try {
    return await customAxios.get(`${API_PATHS.paymentInfo}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * get bank list
 */
export async function getBankList() {
  try {
    return await customAxios.get(`${API_PATHS.bankList}/banks`);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * collect payment
 * @param {{customer_phone_number: string, amount: number, request_review}} data - Data for generating payment link
 */
export async function collectPayment(data) {
  try {
    return await customAxios.post(`${API_PATHS.collectPayment}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Check Promo Code

export async function checkPromoCode({ code }) {
  try {
    return customAxios
      .post(`${API_PATHS.checkPromoCode(code)}`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Make payment using Flutterwave
 * @param {{amount: number}} data
 */
export async function payWithFlutterwave(data) {
  try {
    return await customAxios.post(`${API_PATHS.paymentWithFlutterwave}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Confirm flutterwave payment status
 * @param {{transaction_id: (number|*)}} data
 */
export async function checkFlutterwavePaymentStatus(data) {
  try {
    return await customAxios.post(
      `${API_PATHS.confirmPaymentViaFlutterwave}`,
      data
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getLocalAdZones({
  countryCode,
  region = "",
  name = "",
  skip = 0,
  limit = 5000,
}) {
  try {
    const params = new URLSearchParams({
      country_code: countryCode,
      ...(region || {}),
      ...(name || {}),
      skip,
      limit,
    });
    return await customAxios.get(`${API_PATHS.getAdZones(params.toString())}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function uploadDocument({ name, document }) {
  try {
    return customAxios.post(
      `${API_PATHS.uploadDocument(name)}`,
      { document },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllSubscriptionHistory() {
  try {
    return await customAxios.get(`${API_PATHS.getSubscriptionHistory}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getSubscriptionPackages() {
  try {
    return await customAxios.get(`${API_PATHS.getSubscriptionPackages}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateSubscriptionPackage({
  packageId,
  interval,
  startDate,
}) {
  try {
    return await customAxios.put(`${API_PATHS.updateSubscriptionPackages}`, {
      package_id: packageId,
      interval,
      start_date: startDate,
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function listSocialLinks() {
  try {
    return await customAxios.get(`${API_PATHS.socialLinks}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Create social links for a merchant
 * @param { { data: { facebook: string, instagram: string, website: string, others: string } } } data - Social media urls to create
 * @returns {Promise <AxiosResponse>} A promise that resolves to the response data if the request succeeds,
 */
export async function createSocialLinks({ data }) {
  try {
    return await customAxios.post(`${API_PATHS.createSocialLinks}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 *
 * @param {{ data: { facebook: string, instagram: string, website: string, others: string }}} data - Social media urls to update
 * @returns {Promise <AxiosResponse>} A promise that resolves to the response data if the request succeeds,
 */
export async function updateSocialLinks({ data }) {
  try {
    return await customAxios.put(`${API_PATHS.socialLinks}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Generate an ad description using AI
 * @param {{ goal: string, target_audience: string, location: string, age: string, gender: string }} data - The ad data
 * @returns AxiosResponse<any>
 */
export async function generateAdDescriptionWithAi(data) {
  try {
    return await customAxios.post(
      `${API_PATHS.generateAdDescriptionWithAi}`,
      data
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFacebookAdStatistics({ adId }) {
  try {
    return await customAxios.get(`${API_PATHS.getFacebookAdStatistics(adId)}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function sendNotification({ data }) {
  try {
    return await customAxios.post(`${API_PATHS.sendNotification}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function completeFacebookSetup({ data }) {
  try {
    return await customAxios.post(`${API_PATHS.completeFacebookSetup}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function listConnectedFacebookPages() {
  try {
    return await customAxios.get(`${API_PATHS.listConnectedFacebookPages}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addFacebookPage(data) {
  try {
    return await customAxios.post(
      `${API_PATHS.listConnectedFacebookPages}`,
      data
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function approveFacebookAd({ adId }) {
  try {
    return await customAxios.put(`${API_PATHS.approveFacebookAd(adId)}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function immediatelyPostFacebookAd() {
  try {
    return await customAxios.post(`${API_PATHS.immediatelyPostFacebookAd}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPasswordResetLink(payload) {
  try {
    return await customAxios.post(`${API_PATHS.forgotPassword}`, payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function resetForgottenPassword(payload) {
  if (payload.verifyToken) {
    return axios.post(
      `${RESOLVED_BASE_URL}${API_PATHS.resetForgottenPassword}?verify_token=true`,
      payload
    );
  }
  try {
    // We don't use the customAxios here because we don't want to log users out when we get a 401 for this request
    return await axios.post(
      `${RESOLVED_BASE_URL}${API_PATHS.resetForgottenPassword}`,
      payload
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function listAccessibleMerchantAccounts(limit = 100, offset = 0) {
  const params = new URLSearchParams({ limit, offset });
  try {
    return await customAxios.get(
      `${API_PATHS.listAccessibleMerchantAccounts}?${params.toString()}`
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function searchAccessibleMerchantAccounts(
  businessName,
  limit = 100,
  offset = 0
) {
  const params = new URLSearchParams({
    limit,
    offset,
    business_name: businessName,
  });
  try {
    return await customAxios.get(
      `${API_PATHS.searchAccessibleMerchantAccounts}?${params.toString()}`
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function switchMerchantAccount({ merchantId, refreshToken }) {
  try {
    return await customAxios.post(
      `${API_PATHS.switchMerchantAccount}`,
      {},
      {
        headers: {
          "x-merchant-id": merchantId,
          "x-refresh-token": refreshToken,
        },
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createMpesaIntent(data, merchantId) {
  try {
    return await customAxios.post(
      `${API_PATHS.createIntent(merchantId)}`,
      data
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function confirmMpesaPayment(intent) {
  try {
    return await customAxios.get(`${API_PATHS.confirmPayment(intent)}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function mpesaSDKPrompt(merchantId, data) {
  try {
    return await customAxios.post(
      `${API_PATHS.mpesaSDKPrompt(merchantId)}`,
      data
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function generateAITextMarketing(text, productID) {
  const data = {
    message: text,
  };

  if (productID) {
    data.product_id = productID;
  }

  try {
    return await customAxios.post(`${API_PATHS.aiTextMarketing}`, data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function uploadProductPhotosMiniApp(data) {
  try {
    return await customAxios
      .post(`${API_PATHS.uploadPhotoMiniApp}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateProductDetailsMiniAppHandler(productId, data) {
  try {
    return await customAxios
      .post(`${API_PATHS.updateProductDetailsMiniApp(productId)}`, data)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export const googleAnalytics = async (merchantID) => {
  const today = moment().format("YYYY-MM-DD");
  const url = `${API_PATHS.getGoogleAnalyticsData}?merchant_id=${merchantID}&start_date=2024-01-01&end_date=${today}`;
  try {
    return await customAxios.get(url);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDocuments = async () => {
  const url = `${API_PATHS.getDocument}`;
  try {
    return await customAxios.get(url);
  } catch (error) {
    return Promise.reject(error);
  }
};

export async function createCouponProgram(merchantId, data) {
  try {
    return await customAxios
      .post(`${API_PATHS.coupons}`, data)
      .then((res) => res);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchAllCouponProgram(merchantId, offset) {
  try {
    return await customAxios
      .get(`${API_PATHS.coupons}/${merchantId}?skip=${offset || 0}&limit=50`)
      .then((res) => res.data.filter((c) => c?.is_deleted === false));
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchAllCoupons(couponProgramId) {
  try {
    return await customAxios
      .get(`${API_PATHS.coupons}/${couponProgramId}/coupons?skip=0&limit=50`)
      .then((res) => res.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function redeemCoupon(merchantId, couponCode) {
  try {
    return await customAxios
      .post(`${API_PATHS.coupons}/coupons/${couponCode}/redeem/${merchantId}`)
      .then((res) => res);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function pauseCoupon(isPaused, couponProgramId) {
  try {
    return await customAxios
      .post(
        `${API_PATHS.coupons}/${couponProgramId}/pause?is_paused=${isPaused}`
      )
      .then((res) => res);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteCoupon(couponProgramId) {
  try {
    return await customAxios
      .delete(`${API_PATHS.coupons}/${couponProgramId}`)
      .then((res) => res);
  } catch (error) {
    return Promise.reject(error);
  }
}

export { API_PATHS };
