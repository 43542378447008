import React, { useState } from "react";
import PropTypes from "prop-types";
import Rating from "react-rating";
import moment from "moment/moment";
import ReactModal from "react-modal";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

import FilledStar from "../assets/icons/filledStar.svg";
import EmptyStar from "../assets/icons/emptyStar.svg";
import LabelledTextarea from "./forms/LabelledTextarea";
import Button from "./forms/Button";
import {
  replyToReview,
  replyToReviewKE,
  sendNotification,
} from "../utils/requests-v2";
import { useAuth } from "../utils/Contexts/V2/AuthenticationContext";
import resolveSubdomain from "../utils/resolveSubdomain";
import { useAppContext } from "../utils/Contexts/AppContext";
import obsfucateString from "../utils/obsfucateString";

const modalStyles = {
  content: {
    inset: "auto 0 0 0",
    borderRadius: "16px 16px 0 0",
  },
  overlay: {
    backgroundColor: "#00000033",
  },
};
//
// const REPLY_REVIEW_URL = {
//   tappi: replyToReviewKE,
//   thryve: replyToReview,
// };

const REPLY_REVIEW_URL = {
  tappi: replyToReviewKE,
  thryve: replyToReview,
};

export default function ReviewCard({ review }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      reply: review?.reply?.text || "",
    },
  });

  const { user } = useAuth();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const queryClient = useQueryClient();

  const [isReplying, setIsReplying] = useState(false);

  const { appVendor } = useAppContext();

  const handleReplyReview = async (data) => {
    setIsReplying(true);
    await REPLY_REVIEW_URL[appVendor]({
      reviewId: appVendor === "tappi" ? review.review_id : review.reviewID,
      data,
    })
      .then(async () => {
        try {
          await sendNotification({
            data: {
              notification_type: "Reviews",
              title: "Review Replied To",
              description: `Hi there, ${
                user.business_name
              } just dropped a reply to your review. See reply: "${
                data.reply
              }". Your review: "${
                review.review
              }". Merchant Webiste: ${resolveSubdomain(appVendor, user)}`,
              channel: review.phone ? ["sms"] : ["email"],
              ...(review.phone
                ? { customer_phone_number: review.phone.replace("+", "") } // Replace leading "+" in phone number
                : { customer_email: review.email }),
              is_read: false,
            },
          });
          await queryClient.invalidateQueries(["/reviews"]);
          toast.success("Successfully replied to review");
          closeModal();
          return Promise.resolve();
        } catch (error) {
          console.error("Could not send notification : ", error);
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        console.error("Could not submit reply : ", error);
        toast.error("Could not submit reply. Please try again.");
      })
      .finally(() => setIsReplying(false));
  };

  const { appTheme } = useAppContext();

  return (
    <div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <div className={appTheme}>
          <div
            className="modal-header py-4"
            style={{ borderBottom: "1px solid #ded4d4" }}
          >
            <div className="flex justify-between items-center px-4">
              <h3>Reply Review</h3>
              <button type="button" onClick={closeModal}>
                <svg
                  viewBox="0 0 1024 1024"
                  fill="currentColor"
                  height="1.5em"
                  width="1.5em"
                >
                  <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
                  <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                </svg>
              </button>
            </div>
          </div>
          <div className="card-body p-4 pb-0">
            <div className="flex justify-start">
              <div className="h-10 w-10 rounded-full bg-[#D9D9D9] flex items-center justify-center">
                <p className="font-semibold">
                  {review?.name?.slice(0)[0].trim()?.toUpperCase() ||
                    review?.email?.slice(0)[0].trim()?.toUpperCase() ||
                    "*"}
                </p>
              </div>
              <div className="flex flex-col ml-2">
                <p>{review?.name}</p>
                <Rating
                  placeholderRating={parseInt(review?.rating, 10) || 0}
                  emptySymbol={
                    <img src={EmptyStar} className="icon" alt="empty star" />
                  }
                  placeholderSymbol={
                    <img src={FilledStar} className="icon" alt="empty star" />
                  }
                  fullSymbol={
                    <img src={FilledStar} className="icon" alt="filled star" />
                  }
                  readonly
                />
              </div>
            </div>
            <p>{review?.review}</p>
            <p className="text-sm text-[#56637099]">
              {moment(review?.create_date).format("MMMM Do, YYYY")}
            </p>

            <form className="mt-4" onSubmit={handleSubmit(handleReplyReview)}>
              <LabelledTextarea
                name="reply"
                label="Reply Here"
                register={register}
                id="replyReviewInput"
                rules={{ required: true }}
              />

              <Button type="submit" isLoading={isReplying}>
                Submit Reply
              </Button>
            </form>
          </div>
        </div>
      </ReactModal>
      <button
        className="card review-card flex justify-start border rounded-lg cursor-pointer text-left w-full"
        onClick={() => setIsModalOpen(true)}
        type="button"
      >
        <div className="card-body block p-4 rounded-lg bg-white w-full">
          <div className="card-heading flex">
            <div className="h-10 w-10 rounded-full bg-[#D9D9D9] flex items-center justify-center">
              <p className="font-semibold">
                {review?.name?.slice(0)[0].trim()?.toUpperCase() ||
                  review?.email?.slice(0)[0].trim()?.toUpperCase() ||
                  "*"}
              </p>
            </div>
            <div className="flex flex-col flex-grow ml-2">
              <p className="text-xs font-medium">
                {review?.name?.trim() ||
                  (review?.phone &&
                    obsfucateString({
                      string: review?.phone,
                      lengthHidden: 8,
                    })) ||
                  review?.email}
              </p>
              <Rating
                placeholderRating={parseInt(review?.rating, 10) || 0}
                emptySymbol={
                  <img src={EmptyStar} className="icon" alt="empty star" />
                }
                placeholderSymbol={
                  <img src={FilledStar} className="icon" alt="empty star" />
                }
                fullSymbol={
                  <img src={FilledStar} className="icon" alt="filled star" />
                }
                readonly
              />
            </div>
            {review?.verified &&
              (review?.verified_type === "PURCHASED" ? (
                <p className="text-xs text-[#45C78A]">Verified Purchase</p>
              ) : (
                <p className="text-xs text-[#F3C341]">Verified Review</p>
              ))}
          </div>
          <p className="text-gray-700 text-sm my-2">{review?.review}</p>

          <div className="flex justify-between align-center">
            <p className="text-sm text-[#56637099]">
              {moment(review?.create_date)?.format("MMMM Do, YYYY")}
            </p>
            {review?.reply?.text && (
              <div className="flex items-center space-x-2">
                <svg
                  fill="#56637099"
                  viewBox="0 0 16 16"
                  height="0.75rem"
                  width="0.75rem"
                >
                  <path d="M14 1a1 1 0 011 1v8a1 1 0 01-1 1H4.414A2 2 0 003 11.586l-2 2V2a1 1 0 011-1h12zM2 0a2 2 0 00-2 2v12.793a.5.5 0 00.854.353l2.853-2.853A1 1 0 014.414 12H14a2 2 0 002-2V2a2 2 0 00-2-2H2z" />
                </svg>
                <p className="text-sm text-[#56637099]">1 reply</p>
              </div>
            )}
          </div>
        </div>
      </button>
    </div>
  );
}

ReviewCard.propTypes = {
  review: PropTypes.shape({
    reviewID: PropTypes.number.isRequired,
    review_id: PropTypes.number.isRequired,
    rating: PropTypes.number.isRequired,
    review: PropTypes.string.isRequired,
    create_date: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    verified: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    verified_type: PropTypes.string.isRequired,
    reply: PropTypes.shape({
      create_date: PropTypes.string.isRequired,
      text: PropTypes.oneOfType(PropTypes.string, null),
    }).isRequired,
  }).isRequired,
};
