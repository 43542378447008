import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { useQuery } from "@tanstack/react-query";
// import OffersTab from "../../components/V2/OffersTab";
import Layout from "../../components/layout/V2/Layout";
import Reviews from "../../components/V2/Reviews";
import CustomerTab from "../../components/V2/CustomerTab";
// import { getMerchantReviews } from "../utils/requests";
// import { useBusinessContext } from "../utils/Contexts/BusinessContext";
// import { useAuth } from "../utils/Contexts/AuthenticationContext";

export default function CustomersPage() {
  const location = useLocation();

  // const { updateBusiness } = useBusinessContext();
  // const { user } = useAuth();

  const [activeTab, setActiveTab] = useState("customers");
  const [showShareDialogue, setShowShareDialogue] = useState(false);
  const [operation, setOperation] = useState(null);

  const showDialogueHandler = (bool, type) => {
    setShowShareDialogue(bool);
    setOperation(type);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const tab = params.get("tab") || "customers";

    setActiveTab(tab);
  }, [location]);

  // const limit = 20;
  //
  // const offset = 0;

  // const { data: reviews, isLoading } = useQuery(
  //   ["/reviews", offset],
  //   async () => {
  //     try {
  //       return await getMerchantReviews({
  //         joinCode: user.merchant.join_code,
  //         limit,
  //         offset,
  //         showNumber: false,
  //       }).then((res) => {
  //         updateBusiness({ reviews: res });
  //         return res;
  //       });
  //     } catch (error) {
  //       console.log(error);
  //       return Promise.reject(error);
  //     }
  //   }
  // );

  return (
    <Layout
      headerTitle="Customers"
      showDialogueHandler={showDialogueHandler}
      operation={operation}
      show={showShareDialogue}
    >
      <div className="relative overflow-y-auto min-h-screen scroll-smooth pt-[60px] w-full lg:max-w-screen-lg container mx-auto flex flex-col">
        <ul
          className="nav nav-tabs secondary nav-justified flex flex-row list-none border-b-0 pl-0"
          id="tabs-tabJustify"
          role="tablist"
        >
          <li className="nav-item flex-grow text-center" role="presentation">
            <a
              href="#customers-tab"
              className={`nav-link w-full block font-bold text-sm leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-5 hover:border-transparent hover:bg-gray-100 focus:border-transparent ${
                activeTab === "customers" ? "active" : ""
              }`}
              id="tabs-home-tabJustify"
              data-bs-toggle="pill"
              data-bs-target="#customers-tab"
              role="tab"
              aria-controls="customers-tab"
              aria-selected={activeTab === "customers"}
            >
              Customers
            </a>
          </li>
          {/* <li className="nav-item flex-grow text-start" role="presentation">
            <a
              href="#offers-tab"
              className={`nav-link w-full block font-bold text-sm leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-5 hover:border-transparent hover:bg-gray-100 focus:border-transparent ${
                activeTab === "offers" ? "active" : ""
              }`}
              id="tabs-profile-tabJustify"
              data-bs-toggle="pill"
              data-bs-target="#offers-tab"
              role="tab"
              aria-controls="offers-tab"
              aria-selected={activeTab === "offers"}
            >
              Offers
            </a>
          </li> */}
          <li className="nav-item flex-grow text-center" role="presentation">
            <a
              href="#reviews-tab"
              className={`nav-link w-full block font-bold text-sm leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-5 hover:border-transparent hover:bg-gray-100 focus:border-transparent ${
                activeTab === "reviews" ? "active" : ""
              }`}
              id="tabs-messages-tabJustify"
              data-bs-toggle="pill"
              data-bs-target="#reviews-tab"
              role="tab"
              aria-controls="review"
              aria-selected={activeTab === "reviews"}
            >
              Reviews
            </a>
          </li>
        </ul>
        <div
          className="tab-content flex-grow flex h-full"
          id="tabs-tabContentJustify"
        >
          <div
            className={`tab-pane fade mx-auto w-full ${
              activeTab === "customers" ? " show active" : ""
            } w-full`}
            id="customers-tab"
            role="tabpanel"
            aria-labelledby="tabs-home-tabJustify"
          >
            <CustomerTab />
          </div>
          {/* <div
            className={`tab-pane fade mx-auto w-full ${
              activeTab === "offers" ? " show active" : ""
            }`}
            id="offers-tab"
            role="tabpanel"
            aria-labelledby="tabs-offers-tabJustify"
          >
            <OffersTab />
          </div> */}
          <div
            className={`tab-pane fade mx-auto w-full ${
              activeTab === "reviews" ? " show active" : ""
            }`}
            id="reviews-tab"
            role="tabpanel"
            aria-labelledby="tabs-reviews-tabJustify"
          >
            <Reviews showDialogueHandler={showDialogueHandler} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

// {
//   isLoading || !user ? (
//     <div className="relative overflow-y-auto min-h-screen scroll-smooth pt-32 w-screen px-2 pb-28">
//       <div className="w-full flex justify-center items-center">
//         <div
//           className="spinner-border animate-spin inline-block w-12 h-12 border-8 rounded-full text-purple-50"
//           role="status"
//         >
//           <span className="visually-hidden">Loading...</span>
//         </div>
//       </div>
//     </div>
//   ) : (
//     <div className="relative overflow-y-auto min-h-screen scroll-smooth pt-[60px] w-screen px-2 pb-28 flex flex-col">
//       <ul
//         className="nav nav-tabs secondary nav-justified flex flex-row list-none border-b-0 pl-0 mb-4"
//         id="tabs-tabJustify"
//         role="tablist"
//       >
//         <li className="nav-item text-center" role="presentation">
//           <a
//             href="#customers-tab"
//             className={`nav-link w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent ${
//               activeTab === "customers" ? "active" : ""
//             }`}
//             id="tabs-home-tabJustify"
//             data-bs-toggle="pill"
//             data-bs-target="#customers-tab"
//             role="tab"
//             aria-controls="customers-tab"
//             aria-selected={activeTab === "customers"}
//           >
//             Customers
//           </a>
//         </li>
//         <li className="nav-item flex-grow text-center" role="presentation">
//           <a
//             href="#offers-tab"
//             className={`nav-link w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent ${
//               activeTab === "offers" ? "active" : ""
//             }`}
//             id="tabs-profile-tabJustify"
//             data-bs-toggle="pill"
//             data-bs-target="#offers-tab"
//             role="tab"
//             aria-controls="offers-tab"
//             aria-selected={activeTab === "offers"}
//           >
//             Offers
//           </a>
//         </li>
//         <li className="nav-item flex-grow text-center" role="presentation">
//           <a
//             href="#reviews-tab"
//             className={`nav-link w-full block font-medium text-xs leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent ${
//               activeTab === "reviews" ? "active" : ""
//             }`}
//             id="tabs-messages-tabJustify"
//             data-bs-toggle="pill"
//             data-bs-target="#reviews-tab"
//             role="tab"
//             aria-controls="review"
//             aria-selected={activeTab === "reviews"}
//           >
//             Reviews
//           </a>
//         </li>
//       </ul>
//       <div
//         className="tab-content flex-grow flex"
//         id="tabs-tabContentJustify"
//       >
//         <div
//           className={`tab-pane fade mx-auto ${
//             activeTab === "customers" ? " show active" : ""
//           }`}
//           id="customers-tab"
//           role="tabpanel"
//           aria-labelledby="tabs-home-tabJustify"
//         >
//           <CustomerTab />
//         </div>
//         <div
//           className={`tab-pane fade mx-auto ${
//             activeTab === "offers" ? " show active" : ""
//           }`}
//           id="offers-tab"
//           role="tabpanel"
//           aria-labelledby="tabs-offers-tabJustify"
//         >
//           <OffersTab />
//         </div>
//         <div
//           className={`tab-pane fade mx-auto ${
//             activeTab === "reviews" ? " show active" : ""
//           }`}
//           id="reviews-tab"
//           role="tabpanel"
//           aria-labelledby="tabs-reviews-tabJustify"
//         >
//           <Reviews />
//         </div>
//       </div>
//     </div>
//   )}
