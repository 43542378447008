import React from "react";
import { useNavigate } from "react-router-dom";
import { useOffer } from "../../utils/Contexts/V2/OfferContext";
import LabelledTextarea from "../forms/LabelledTextarea";

function AITextMarketingDescUserDialogue() {
  const navigate = useNavigate();
  const {
    setShowAITextMarketingUserPrompt,
    setShowAITextMarketingPrompt,
    isGeneratingAIText,
    generateAITextMarketingDescHandler,
    setCampaignText,
  } = useOffer();

  return (
    <div className="relative w-screen h-screen  flex justify-center items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute h-full w-full bg-black/[.6]"
        onClick={() => {
          setShowAITextMarketingUserPrompt(false);
          setShowAITextMarketingPrompt(false);
        }}
      />
      <div className="absolute z-20 flex flex-col justify-start items-center space-y-5 w-full px-4 pt-[1.7rem] pb-6 bg-white rounded-t-2xl md:rounded-2xl self-end md:self-center lg:max-w-screen-sm">
        <p className="text-base font-semibold">
          Describe your Text Marketing Campaign
        </p>
        <p className="text-base font-normal">
          Tell us what your message should focus on. This helps AI create a
          tailored and effective text campaign for you
        </p>
        <LabelledTextarea
          label="Description"
          placeholder="e.g Promote our new product, annouce a sale or shareexciting news"
          name="description"
          id="description"
          onChange={(e) => setCampaignText(e.target.value)}
        />
        <div className="flex flex-col space-y-3 w-full">
          <button
            type="button"
            className="w-full h-12 text-white text-base font-semibold rounded-xl bg-primary-50 thryve:text-base"
            onClick={() => generateAITextMarketingDescHandler(navigate)}
          >
            {!isGeneratingAIText ? "Continue" : "Generating text..."}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AITextMarketingDescUserDialogue;
