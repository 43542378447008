import { useEffect, useState } from "react";
import { googleAnalytics } from "../requests-v2";
import { useAuth } from "../Contexts/V2/AuthenticationContext";

const useGoogleAnalytics = () => {
  const { user } = useAuth();
  const [loadingStats, setLoadingStats] = useState(true);
  const [stats, setStats] = useState({ reach: 0, engagement: 0 });

  useEffect(() => {
    (async () => {
      setLoadingStats(true);
      try {
        const response = await googleAnalytics(user?.id);
        setStats({
          reach: response.data
            .map((i) => i.screen_page_views)
            .reduce((a, b) => a + b, 0),
          engagement: response.data
            .map((i) => i.event_count)
            .reduce((a, b) => a + b, 0),
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingStats(false);
      }
    })();
  }, []);

  return { loadingStats, stats };
};

export default useGoogleAnalytics;
