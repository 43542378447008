import React from "react";
import PropTypes from "prop-types";

export default function CustomerDetails({
  contact,
  setShowCustomerDetails,
  setShowDeleteCustomerPrompt,
}) {
  const removeCustomerHandler = () => {
    setShowDeleteCustomerPrompt(true);
    setShowCustomerDetails(false);
  };

  const displayPicHandler = (name) => {
    const abbreviatedName = name.split(" ");
    if (abbreviatedName.length >= 2) {
      return `${Array.from(abbreviatedName[0])[0].toUpperCase()}${Array.from(
        abbreviatedName[1]
      )[0].toUpperCase()}`;
    }
    return `${Array.from(abbreviatedName[0])[0].toUpperCase()}`;
  };
  return (
    <div className="relative w-screen h-screen  flex justify-center items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute h-full w-full bg-black/[.6]"
        onClick={() => setShowCustomerDetails(false)}
      />
      <div className="absolute z-20 flex flex-col justify-start items-center space-y-5 w-full px-4 pt-[1.7rem] pb-6 bg-white rounded-t-2xl md:rounded-2xl self-end md:self-center lg:max-w-screen-sm">
        <div className="w-full flex flex-row justify-between items-center pb-[1.12rem] px-4">
          <div />
          <button type="button" onClick={() => setShowCustomerDetails(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0079 18.5383L4.7651 6.29552C4.34681 5.87722 4.34681 5.18347 4.7651 4.76517C5.1834 4.34688 5.87715 4.34688 6.29545 4.76517L18.5382 17.0079C18.9565 17.4262 18.9565 18.12 18.5382 18.5383C18.1199 18.9566 17.4262 18.9566 17.0079 18.5383Z"
                fill="#1A1A1A"
              />
              <path
                d="M4.76551 18.5383C4.34721 18.12 4.34721 17.4262 4.76551 17.0079L17.0083 4.76517C17.4266 4.34688 18.1203 4.34688 18.5386 4.76517C18.9569 5.18347 18.9569 5.87722 18.5386 6.29552L6.29585 18.5383C5.87756 18.9566 5.1838 18.9566 4.76551 18.5383Z"
                fill="#1A1A1A"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col justify-start items-center pt-[1.04rem]">
          <div className="flex flex-col justify-start items-center space-y-4">
            <div className="w-20 h-20 bg-[#D9D9D9] rounded-full p-4 flex justify-center items-center">
              <p className="font-bold text-2xl">
                {displayPicHandler(contact[0].full_name)}
              </p>
            </div>
            <p className="font-bold text-lg">{contact[0].full_name}</p>
            <p className="font-normal text-xs text-left text-[#566370]">
              {contact[0]?.phone_number}
            </p>
          </div>
          <div className="w-full flex flex-row justify-between items-center py-8 px-[1.3rem] space-x-20">
            <a
              href={`tel:${contact[0]?.phone_number}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex flex-col justify-start items-center space-y-2">
                <div className="w-14 h-14 bg-[#D9D9D9] rounded-full p-4 flex justify-center items-center">
                  <svg
                    fill="#000000"
                    height="28px"
                    width="28px"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 463.009 463.009"
                    enableBackground="new 0 0 463.009 463.009"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <g>
                        {" "}
                        <path d="m462.692,381.085c-1.472-11.126-7.895-20.719-17.62-26.318l-114.226-65.767c-13.99-8.055-31.738-5.71-43.157,5.708l-22.499,22.499c-5.987,5.988-15.459,6.518-22.028,1.231-17.737-14.272-35.201-29.979-51.906-46.685-16.705-16.705-32.412-34.168-46.685-51.906-5.287-6.57-4.758-16.041 1.231-22.029l22.498-22.499c11.418-11.417 13.766-29.163 5.709-43.156l-65.767-114.226c-5.6-9.726-15.192-16.148-26.318-17.62-11.127-1.475-22.06,2.236-29.996,10.172l-33.901,33.902c-23.661,23.662-24.041,66.944-1.07,121.875 22.088,52.818 63.308,110.962 116.065,163.721 52.759,52.758 110.903,93.978 163.722,116.066 27.039,11.307 51.253,16.957 71.697,16.956 21.088,0 38.163-6.013 50.178-18.027l33.901-33.902c7.935-7.936 11.643-18.869 10.172-29.995zm-139.33-79.086l114.226,65.767c5.649,3.252 9.379,8.824 10.233,15.286 0.718,5.423-0.691,10.763-3.885,15.066l-151.805-86.638 6.165-6.165c6.631-6.631 16.941-7.994 25.066-3.316zm-243.406-286.811c6.463,0.855 12.034,4.585 15.286,10.234l65.767,114.226c4.68,8.127 3.316,18.435-3.315,25.065l-5.663,5.663-87.114-151.303c3.561-2.637 7.82-4.069 12.26-4.069 0.921-1.77636e-15 1.85,0.061 2.779,0.184zm328.055,419.187c-18.798,18.798-57.244,18.01-105.48-2.162-51.06-21.352-107.491-61.424-158.901-112.833-51.41-51.41-91.482-107.842-112.834-158.901-20.173-48.237-20.96-86.683-2.162-105.482l25.167-25.168 87.245,151.532-5.851,5.851c-11.415,11.416-12.409,29.488-2.311,42.04 14.609,18.156 30.68,36.024 47.764,53.108 17.086,17.085 34.954,33.156 53.109,47.765 12.55,10.098 30.622,9.105 42.04-2.312l5.338-5.338 152.016,86.759-25.14,25.141z" />{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </div>
                <div className="flex flex-col justify-start items-center space-y-px font-bold text-xs text-[#1A1A1A]">
                  <p>Call</p>
                  <p>Customer</p>
                </div>
              </div>
            </a>
            <button type="button" onClick={removeCustomerHandler}>
              <div className="flex flex-col justify-start items-center space-y-2">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="24" cy="24" r="24" fill="#F04438" />
                  <path
                    d="M21.7917 16.6667H26.375C26.375 16.0589 26.1336 15.476 25.7038 15.0462C25.274 14.6164 24.6911 14.375 24.0833 14.375C23.4755 14.375 22.8927 14.6164 22.4629 15.0462C22.0331 15.476 21.7917 16.0589 21.7917 16.6667ZM20.4167 16.6667C20.4167 15.6942 20.803 14.7616 21.4906 14.0739C22.1782 13.3863 23.1109 13 24.0833 13C25.0558 13 25.9884 13.3863 26.6761 14.0739C27.3637 14.7616 27.75 15.6942 27.75 16.6667H33.4792C33.6615 16.6667 33.8364 16.7391 33.9653 16.868C34.0942 16.997 34.1667 17.1718 34.1667 17.3542C34.1667 17.5365 34.0942 17.7114 33.9653 17.8403C33.8364 17.9692 33.6615 18.0417 33.4792 18.0417H32.2783L31.1628 31.4278C31.0816 32.4015 30.6374 33.3093 29.9184 33.971C29.1993 34.6326 28.2578 34.9999 27.2807 35H20.886C19.9088 34.9999 18.9674 34.6326 18.2483 33.971C17.5292 33.3093 17.0851 32.4015 17.0039 31.4278L15.8883 18.0417H14.6875C14.5052 18.0417 14.3303 17.9692 14.2014 17.8403C14.0724 17.7114 14 17.5365 14 17.3542C14 17.1718 14.0724 16.997 14.2014 16.868C14.3303 16.7391 14.5052 16.6667 14.6875 16.6667H20.4167ZM22.7083 21.9375C22.7083 21.7552 22.6359 21.5803 22.507 21.4514C22.378 21.3224 22.2032 21.25 22.0208 21.25C21.8385 21.25 21.6636 21.3224 21.5347 21.4514C21.4058 21.5803 21.3333 21.7552 21.3333 21.9375V29.7292C21.3333 29.9115 21.4058 30.0864 21.5347 30.2153C21.6636 30.3442 21.8385 30.4167 22.0208 30.4167C22.2032 30.4167 22.378 30.3442 22.507 30.2153C22.6359 30.0864 22.7083 29.9115 22.7083 29.7292V21.9375ZM26.1458 21.25C25.9635 21.25 25.7886 21.3224 25.6597 21.4514C25.5308 21.5803 25.4583 21.7552 25.4583 21.9375V29.7292C25.4583 29.9115 25.5308 30.0864 25.6597 30.2153C25.7886 30.3442 25.9635 30.4167 26.1458 30.4167C26.3282 30.4167 26.503 30.3442 26.632 30.2153C26.7609 30.0864 26.8333 29.9115 26.8333 29.7292V21.9375C26.8333 21.7552 26.7609 21.5803 26.632 21.4514C26.503 21.3224 26.3282 21.25 26.1458 21.25Z"
                    fill="white"
                  />
                </svg>
                <div className="flex flex-col justify-start items-center space-y-px font-bold text-xs text-[#1A1A1A]">
                  <p>Remove</p>
                  <p>Customer</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

CustomerDetails.defaultProps = {
  contact: null,
};

CustomerDetails.propTypes = {
  contact: PropTypes.objectOf(PropTypes.string),
  setShowCustomerDetails: PropTypes.func.isRequired,
  setShowDeleteCustomerPrompt: PropTypes.func.isRequired,
};
