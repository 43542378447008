import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import {
  createOffer,
  deleteOffer,
  editOffer,
  generateAITextMarketing,
  getAllOffers,
  getOfferContacts,
  postBulkOffer,
  postOffer,
  topUpBulkSMS,
} from "../../requests-v2";

import { useWalletContext } from "./WalletContext";
import { useAuth } from "./AuthenticationContext";
import { useAppContext } from "../AppContext";

const OfferContext = createContext(null);

function useOffer() {
  return useContext(OfferContext);
}

const ID_IDENTIFIER = {
  tappi: "TAP",
  thryve: "EN",
};

function OfferProvider({ children }) {
  const { user } = useAuth();
  const { appTheme } = useAppContext();
  const { walletDetails } = useWalletContext();
  const [offers, setOffers] = useState(null);
  const [isLoadingOfferData, setIsLoadingOfferData] = useState(false);
  const [isCreatingOffer, setIsCreatingOffer] = useState(false);
  const [isDeletingOffer, setIsDeletingOffer] = useState(false);
  const [isSendingOffer, setIsSendingOffer] = useState(false);
  const [isTopingUp, setIsTopingUp] = useState(false);
  const [selectedCustomersForOffer, setSelectedCustomersForOffer] = useState(
    JSON.parse(localStorage.getItem("CONTACTS")) || []
  );
  const [isModified, setIsModified] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [newOffer, setNewOffer] = useState({
    title: "",
    description: "",
    is_active: false,
    used_ai: false,
    add_link: true,
    select_all_contacts: false,
  });
  const [offersContacts, setOffersContacts] = useState([]);
  const [showSociableHourBanner, setShowSociableHourBanner] = useState(false);
  const [showAITextMarketingPrompt, setShowAITextMarketingPrompt] =
    useState(false);
  const [showAITextMarketingUserPrompt, setShowAITextMarketingUserPrompt] =
    useState(false);
  const [isGeneratingAIText, setIsGeneratingAIText] = useState(false);
  const [campaignText, setCampaignText] = useState("");

  const selectedCustomersForOfferHandler = (e, contact) => {
    if (e.target.checked) {
      setSelectedCustomersForOffer((prevState) => [...prevState, contact]);
    } else {
      const filterSelectedContact = selectedCustomersForOffer.filter(
        (item) => item.id !== contact.id
      );
      setSelectedCustomersForOffer(filterSelectedContact);
    }
  };

  const newOfferHandler = (e) => {
    if (e.target.value) {
      if (e.target.name === "title") {
        setNewOffer({ ...newOffer, title: e.target.value });
      } else if (e.target.name === "description") {
        setNewOffer({ ...newOffer, description: e.target.value });
      } else if (e.target.name === "add_link") {
        setNewOffer({ ...newOffer, add_link: e.target.checked });
      } else if (e.target.id === "select_all_contacts") {
        setNewOffer({ ...newOffer, select_all_contacts: e.target.checked });
      }
    }
  };

  const createNewOfferHandler = (callback) => {
    if (newOffer.title && newOffer.description) {
      setIsCreatingOffer(true);
      createOffer({
        title: newOffer.title,
        description: newOffer.description,
        is_active: false,
        used_ai: newOffer.used_ai,
      })
        .then((res) => {
          setIsCreatingOffer(false);
          setIsModified(true);
          toast.success("Offer successfully created");
          callback(`/create-offers/select-customers/${res.data.id}`);
          return Promise.resolve(res);
        })
        .catch((err) => {
          setIsCreatingOffer(false);
          toast.error(err?.response?.data?.detail || "Something went wrong");
          return Promise.reject(err);
        });
    } else {
      toast.error("Please check that all fields have been filled");
    }
  };

  const editOfferHandler = (callback, id) => {
    if (newOffer.title && newOffer.description) {
      setIsCreatingOffer(true);
      editOffer(id, {
        title: newOffer.title,
        description: newOffer.description,
        is_active: true,
      })
        .then((res) => {
          setIsCreatingOffer(false);
          setIsModified(true);
          toast.success("Offer successfully edited");
          callback(`/create-offers/select-customers/${id}`);
          return Promise.resolve(res);
        })
        .catch((err) => {
          setIsCreatingOffer(false);
          toast.error(err?.response?.data?.detail || "Something went wrong");
          return Promise.reject(err);
        });
    } else {
      toast.error("Please check that all fields have been filled");
    }
  };

  // eslint-disable-next-line consistent-return
  const sendOfferHandler = async (callback, id, removeIsOnlineOffer) => {
    if (walletDetails.bulk_sms_count !== 0) {
      const contactsForOffer = selectedCustomersForOffer.map((item) =>
        item.phone_number.toString()
      );

      const offerToBeSent = offers.filter((item) => item.id === id);

      setIsSendingOffer(true);

      try {
        const response = newOffer?.select_all_contacts
          ? await postBulkOffer(id, newOffer?.add_link)
          : await postOffer(id, {
              send_link: newOffer?.add_link,
              contacts: contactsForOffer,
            });

        if (response.status === 200) {
          await editOffer(id, {
            title: offerToBeSent.title,
            description: offerToBeSent.description,
            is_active: true,
          });

          callback(true);
          setIsModified(true);
          localStorage.removeItem("CONTACTS");
          localStorage.removeItem("NEW_OFFER");
        }
      } catch (err) {
        setIsCreatingOffer(false);
        toast.error(err?.response?.data?.detail || "Something went wrong");
        return Promise.reject(err);
      } finally {
        setIsCreatingOffer(false);
        removeIsOnlineOffer();
      }
    } else {
      toast.error(
        "Insufficient Bulk SMS Balance, Please top up your Bulk SMS to send offers"
      );
    }
  };

  const deleteOfferHandler = (id, callback) => {
    setIsDeletingOffer(true);
    deleteOffer(id)
      .then((res) => {
        setIsModified(true);
        setIsDeletingOffer(false);
        toast.success("Offer successfully deleted");
        callback(false);
        return Promise.resolve(res);
      })
      .catch((err) => {
        setIsDeletingOffer(false);
        toast.error(err?.response?.data?.detail || "Something went wrong");
        return Promise.reject(err);
      });
  };

  const topUpBulkSMSHandler = (quantity, callback, navigate) => {
    if (walletDetails.credit_balance >= Number(quantity)) {
      setIsTopingUp(true);
      topUpBulkSMS(`${ID_IDENTIFIER[appTheme]}-${user?.phone_number}`, {
        bulk_sms_count: Number(quantity),
      })
        .then((res) => {
          setIsTopingUp(false);
          callback(false);
          navigate(`/create-offers/success/top-up/${quantity}`);
          return Promise.resolve(res);
        })
        .catch((err) => {
          setIsTopingUp(false);
          setIsDeletingOffer(false);
          setErrorMessage("Something went wrong");
          return Promise.reject(err);
        });
    } else {
      toast.error("Insufficient Credit balance. Kindly top up your wallet");
    }
  };

  // Sociable hours handler
  const showSociableHoursHandler = () => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();

    // Define start and end times
    const eveningStartHour = 18; // 6:00 PM in 24-hour format
    const morningEndHour = 8; // 8:00 AM in 24-hour format

    // Check if the current time is between 7:00 PM and midnight (0:00) or midnight and 8:00 AM
    if (
      (currentHour === eveningStartHour && currentMinute >= 0) ||
      currentHour > eveningStartHour ||
      currentHour < morningEndHour ||
      (currentHour === morningEndHour && currentMinute === 0)
    ) {
      return setShowSociableHourBanner(true);
    }

    return setShowSociableHourBanner(false);
  };

  const generateAITextMarketingDescHandler = async (navigate) => {
    setIsGeneratingAIText(true);
    try {
      const response = await generateAITextMarketing(campaignText, null);

      const offer = {
        title: response?.data?.title,
        description: response?.data?.offer,
        is_active: false,
        used_ai: true,
        add_link: true,
        select_all_contacts: false,
      };

      setNewOffer(offer);

      try {
        await localStorage.setItem("NEW_OFFER", JSON.stringify(offer));

        navigate("/create-offers");
      } catch (e) {
        throw new Error(e.toString());
      }
    } catch (e) {
      console.error("Could not generate ai description", e);
      toast.error("Something went wrong");
    } finally {
      setIsGeneratingAIText(false);
    }
  };

  useEffect(() => {
    if (user) {
      setIsLoadingOfferData(true);
      getAllOffers()
        .then((res) => {
          setOffers(res.data);
          return res.data;
        })
        .then((res) => {
          setOffersContacts(
            res?.map(async (offer) => {
              try {
                const contacts = await getOfferContacts(offer.id);
                const count = contacts?.data?.length || 0;
                return setOffersContacts((prevState) => [
                  ...prevState,
                  { id: offer.id, count },
                ]);
              } catch (e) {
                return Promise.reject(e);
              }
            })
          );
          setIsLoadingOfferData(false);
        })
        .catch((err) => {
          setIsLoadingOfferData(false);
          setOffers(null);
          return Promise.resolve(err);
        });
    }
  }, [isModified, user]);

  useEffect(() => {
    setIsModified(false);
  }, []);

  useEffect(() => {
    localStorage.setItem("NEW_OFFER", JSON.stringify(newOffer));
    localStorage.setItem("CONTACTS", JSON.stringify(selectedCustomersForOffer));
  }, [newOffer, selectedCustomersForOffer]);

  useEffect(() => {
    const offer = JSON.parse(localStorage.getItem("NEW_OFFER"));
    const contacts = JSON.parse(localStorage.getItem("CONTACTS"));
    if (offer) {
      setNewOffer(offer);
    } else {
      setNewOffer({
        title: "",
        description: "",
        is_active: false,
        used_ai: false,
        add_link: true,
        select_all_contacts: false,
      });
    }

    if (contacts) {
      setSelectedCustomersForOffer(contacts);
    } else {
      setSelectedCustomersForOffer([]);
    }
  }, []);

  const value = useMemo(() => ({
    offers,
    newOffer,
    isLoadingOfferData,
    isCreatingOffer,
    errorMessage,
    selectedCustomersForOffer,
    isDeletingOffer,
    setNewOffer,
    isSendingOffer,
    setSelectedCustomersForOffer,
    isTopingUp,
    offersContacts,
    editOfferHandler,
    deleteOfferHandler,
    newOfferHandler,
    createNewOfferHandler,
    selectedCustomersForOfferHandler,
    sendOfferHandler,
    topUpBulkSMSHandler,
    showSociableHourBanner,
    setShowSociableHourBanner,
    showSociableHoursHandler,
    showAITextMarketingPrompt,
    setShowAITextMarketingPrompt,
    showAITextMarketingUserPrompt,
    setShowAITextMarketingUserPrompt,
    isGeneratingAIText,
    generateAITextMarketingDescHandler,
    setCampaignText,
  }));
  return (
    <OfferContext.Provider value={value}>{children}</OfferContext.Provider>
  );
}

export { useOffer, OfferProvider };

OfferProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
