import React, { useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../components/layout/V2/Layout";
import { useProductPhotosContext } from "../../../utils/Contexts/V2/ProductPhotosContext";
import PhotoUploadConfirmation from "../../../components/V2/PhotoUploadConfirmation";
import { getMerchantProductPhotos } from "../../../utils/requests-v2";
import { ImageIcon } from "./addPhoto";
import CloseIcon from "../../../assets/icons/CloseDark.svg";

function EditPhoto() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const photoID = queryParams.get("id");
  const categoryID = queryParams.get("category");

  const {
    setBusinessPhoto,
    businessPhoto,
    isLoading,
    showError,
    showUploadConfirmation,
    newProductHandler,
    setNewProduct,
    newProduct,
    productCategories,
    updateProductPhotoDetailsHandler,
    onBlurHandler,
    errors,
    uploadProductPhotoMiniAppHandler,
  } = useProductPhotosContext();

  useEffect(() => {
    const query = new URLSearchParams({ id: photoID });
    getMerchantProductPhotos({ query: query.toString() }).then((res) => {
      const photoDetails = res?.items[0];
      console.log("photoDetails", photoDetails);
      const categoryIDParsed = categoryID
        ? parseInt(categoryID, 10)
        : photoDetails.product_category_id;
      const categoryName = categoryID
        ? productCategories.find((p) => p.id === categoryIDParsed)
            ?.category_name
        : productCategories.find(
            (p) => p.id === photoDetails.product_category_id
          )?.category_name;

      setBusinessPhoto(photoDetails?.product_photos);

      setNewProduct({
        product_name: photoDetails.product_name,
        price: photoDetails.price,
        description: photoDetails.description,
        product_category_id: categoryIDParsed,
        product_category_name: categoryName,
        product_link: photoDetails.product_link,
        photo_url: photoDetails.photo_url,
      });
    });
  }, []);

  return (
    <Layout header headerTitle="Edit product photo">
      <div className="relative w-full md:max-w-screen-lg h-[100vh] scroll-smooth pt-20 flex flex-col justify-between overflow-hidden">
        <Transition
          className="fixed -bottom-12 left-0 z-20 w-full"
          show={showUploadConfirmation}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <PhotoUploadConfirmation />
        </Transition>
        <div className="flex flex-col space-y-6 justify-start items-center w-full h-fit pb-28 pt-6 px-4 overflow-y-scroll">
          <div className="w-full shrink-0 h-[15.4rem] flex justify-center items-center">
            {businessPhoto.length > 0 ? (
              <img
                alt="not fount"
                className="z-10 w-full h-[15.4rem] object-contain"
                src={
                  businessPhoto[0].photo_url ||
                  URL.createObjectURL(businessPhoto[0])
                }
              />
            ) : (
              <div className="relative w-full shrink-0 h-[15.4rem] border-[1.5px] border-dashed border-[#4B1D8F] rounded-lg flex justify-center items-center">
                <input
                  type="file"
                  accept="image/*"
                  className=" absolute z-20 w-full h-[15.4rem] outline-none overflow-hidden"
                  onChange={uploadProductPhotoMiniAppHandler}
                />
                <div className="flex flex-col space-y-2 items-center">
                  <ImageIcon />
                  <p className="text-[#667085] text-[0.875rem]">
                    Choose Image(s)
                  </p>
                </div>
              </div>
            )}
          </div>
          <p className="font-graphik-regular text-[0.75rem] text-[#667085]">
            Supported: JPG, PNG, JPEG
          </p>
          <div className="flex flex-row justify-start items-center w-full gap-6">
            {businessPhoto.length < 6 && (
              <div className="relative flex h-[6.375rem] w-[6.375rem] md:h-[10.375rem] md:w-[10.375rem] shrink-0 cursor-pointer flex-col items-center justify-center space-y-[0.5rem] rounded-[0.5rem] border-[1px] border-dashed border-[#4B1D8F]">
                <ImageIcon />
                <p className="text-[#667085] text-xs text-center md:text-[0.875rem]">
                  Add more
                </p>
                <input
                  type="file"
                  className="absolute z-20 h-full w-full cursor-pointer border-none focus:outline-none"
                  onChange={uploadProductPhotoMiniAppHandler}
                />
              </div>
            )}
            <div className="w-full flex flex-row justify-start items-center gap-6 overflow-hidden overflow-x-scroll">
              {businessPhoto.slice(1).map(
                (photo) =>
                  photo && (
                    <div className="relative h-[6.375rem] w-[6.375rem] md:h-[10.375rem] md:w-[10.375rem] shrink-0 overflow-hidden">
                      <button
                        type="button"
                        className="absolute right-1 top-1"
                        onClick={() =>
                          setBusinessPhoto(
                            businessPhoto.filter((item) => item !== photo)
                          )
                        }
                      >
                        <img
                          src={CloseIcon}
                          alt="delete button"
                          className="h-[1.2rem] w-[1.2rem]"
                        />
                      </button>
                      <img
                        src={photo.photo_url || URL.createObjectURL(photo)}
                        alt="product"
                        className="shrink-0 h-full w-full  object-cover rounded-[0.5rem]"
                      />
                    </div>
                  )
              )}
            </div>
          </div>
          <div className="w-full">
            <input
              onChange={newProductHandler}
              className={`w-full rounded-[0.5rem] border-[1px] ${
                errors.product_name ? "border-red-500" : "border-[#E7E7E7]"
              } px-[1rem] py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0`}
              placeholder="Enter product name"
              id="product_name"
              value={newProduct?.product_name}
              onBlur={onBlurHandler}
            />
            {errors.product_name && (
              <p className="text-red-500 mt-2 text-xs">{errors.product_name}</p>
            )}
          </div>
          <input
            className="w-full rounded-[0.5rem] border-[1px] border-[#E7E7E7] px-[1rem] py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0"
            placeholder="Select category (Optional)"
            id="product_category"
            readOnly
            onClick={() =>
              navigate(
                !photoID
                  ? "/add-photos/category"
                  : `/add-photos/category?id=${photoID}`
              )
            }
            value={newProduct?.product_category_name}
          />
          <div className="w-full flex flex-row relative">
            <input
              className="w-full rounded-[0.5rem] border-[1px] border-[#E7E7E7] px-[1rem] py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0"
              placeholder="Enter price (Optional)"
              id="price"
              type="number"
              onChange={newProductHandler}
              value={newProduct.price}
            />
            <p className="absolute right-[1rem] top-[1.2rem] text-[#1A1A1A] text-[0.875rem] font-bold">
              Ksh.
            </p>
          </div>
          <div className="w-full">
            <input
              className={`w-full rounded-[0.5rem] border-[1px] ${
                errors.product_link ? "border-red-500" : "border-[#E7E7E7]"
              } px-[1rem] py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0`}
              placeholder="Paste product link (Optional)"
              id="product_link"
              onChange={newProductHandler}
              value={newProduct.product_link}
              onBlur={onBlurHandler}
            />
            {errors.product_link && (
              <p className="text-red-500 mt-2 text-xs">{errors.product_link}</p>
            )}
          </div>
          <textarea
            className="w-full shrink-0 rounded-[0.5rem] border-[1px] border-[#E7E7E7] px-[1rem] py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0"
            placeholder="Describe your product/service here"
            id="description"
            onChange={newProductHandler}
            rows={5}
            value={newProduct.description}
          />
        </div>
      </div>
      <div className="fixed bottom-0 w-full md:max-w-screen-lg">
        <div className="relative p-4 w-full shadow-[0_-2px_8px_rgba(0, 0, 0, 0.3)] border border-t-solid border-t-[#D1D9DD] bg-white">
          <Transition
            className="absolute -bottom-4 left-0 z-20 w-full flex justify-center items-center"
            show={showError}
            enterFrom=" translate-y-0"
            enterTo=" -translate-y-12"
            leaveFrom=" -translate-y-12"
            leaveTo=" translate-y-0"
          >
            <p className="text-sm text-center py-2 px-4 rounded-xl bg-slate-300/[.8]">
              Please ensure that the form has no errors and the product photo is
              provided.
            </p>
          </Transition>
          <button
            type="button"
            className="w-full h-12 text-white thryve:text-base text-base font-semibold rounded-xl bg-primary-50"
            onClick={() => updateProductPhotoDetailsHandler(photoID)}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {!isLoading ? "Update" : "Updating..."}
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default EditPhoto;
