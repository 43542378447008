import React from "react";
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout/V2/Layout";
import { useProductPhotosContext } from "../../../utils/Contexts/V2/ProductPhotosContext";
import PhotoUploadConfirmation from "../../../components/V2/PhotoUploadConfirmation";
import CloseIcon from "../../../assets/icons/CloseDark.svg";

export function ImageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
    >
      <mask
        id="mask0_9712_3884"
        maskUnits="userSpaceOnUse"
        x="3"
        y="2"
        width="29"
        height="30"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33398 2.83347H31.6007V31.1015H3.33398V2.83347Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_9712_3884)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3396 4.95831C7.76815 4.95831 5.45898 7.40773 5.45898 11.1973V22.7375C5.45898 26.5285 7.76815 28.9765 11.3396 28.9765H23.5881C27.1651 28.9765 29.4757 26.5285 29.4757 22.7375V11.1973C29.4786 9.2664 28.8892 7.65423 27.7729 6.53648C26.7416 5.50373 25.298 4.95831 23.5951 4.95831H11.3396ZM23.5881 31.1015H11.3396C6.55123 31.1015 3.33398 27.7397 3.33398 22.7375V11.1973C3.33398 6.19506 6.55123 2.83331 11.3396 2.83331H23.5952C25.8732 2.83331 27.8381 3.59406 29.276 5.03481C30.7791 6.53931 31.6036 8.72806 31.6007 11.1987V22.7375C31.6007 27.7397 28.3807 31.1015 23.5881 31.1015Z"
          fill="#667085"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0473 10.8935C12.1917 10.8935 11.4961 11.589 11.4961 12.4461C11.4961 13.3018 12.1917 13.9974 13.0488 13.9974C13.9044 13.9974 14.6014 13.3018 14.6014 12.4475C14.6 11.5905 13.903 10.8949 13.0473 10.8935ZM13.0488 16.1224C11.0201 16.1224 9.37109 14.4734 9.37109 12.4461C9.37109 10.4175 11.0201 8.76846 13.0488 8.76846C15.076 8.76988 16.725 10.4189 16.7264 12.4447V12.4461C16.7264 14.4734 15.0774 16.1224 13.0488 16.1224Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.81099 28.491C5.63532 28.491 5.45682 28.4471 5.29249 28.355C4.77966 28.0674 4.59974 27.42 4.88591 26.9086C4.97091 26.7556 7.00382 23.1615 9.24074 21.3198C11.0144 19.8607 12.9241 20.6625 14.4626 21.3099C15.3678 21.691 16.2235 22.0508 17.0452 22.0508C17.7988 22.0508 18.7437 20.7192 19.5796 19.5447C20.7398 17.9071 22.0573 16.0541 23.9868 16.0541C27.061 16.0541 29.7144 18.7967 31.141 20.2701L31.3053 20.4401C31.7133 20.8608 31.7034 21.5337 31.2827 21.9432C30.8647 22.3526 30.1918 22.3427 29.781 21.9205L29.6138 21.7477C28.4068 20.4996 26.16 18.1791 23.9868 18.1791C23.1538 18.1791 22.1763 19.5575 21.3107 20.7744C20.1236 22.4461 18.8953 24.1758 17.0452 24.1758C15.7942 24.1758 14.6482 23.6942 13.6381 23.2677C12.0316 22.5906 11.3034 22.3738 10.5908 22.9603C8.65849 24.5527 6.75732 27.9158 6.73891 27.9484C6.54482 28.2955 6.18357 28.491 5.81099 28.491Z"
        fill="#667085"
      />
    </svg>
  );
}

function AddPhoto() {
  const navigate = useNavigate();
  const {
    setBusinessPhoto,
    handleFileChange,
    businessPhoto,
    isLoading,
    uploadProductPhoto,
    showError,
    showUploadConfirmation,
    newProductHandler,
    newProduct,
    onBlurHandler,
    errors,
  } = useProductPhotosContext();

  return (
    <Layout header headerTitle="Add product photo">
      <div className="relative w-full md:max-w-screen-md h-[100vh] scroll-smooth pt-20 flex flex-col justify-between overflow-hidden">
        <Transition
          className="fixed -bottom-12 left-0 z-20 w-full"
          show={showUploadConfirmation}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <PhotoUploadConfirmation />
        </Transition>
        <div className="flex flex-col space-y-6 justify-start items-center w-full h-fit pb-28 pt-6 px-4 overflow-y-scroll">
          <div className="w-full shrink-0 h-[15.4rem] flex justify-center items-center">
            {businessPhoto.length > 0 ? (
              <img
                alt="not fount"
                className="z-10 w-full h-[15.4rem] object-contain"
                src={URL.createObjectURL(businessPhoto[0])}
              />
            ) : (
              <div className="relative w-full shrink-0 h-[15.4rem] border-[1.5px] border-dashed border-[#4B1D8F] rounded-lg flex justify-center items-center">
                <input
                  type="file"
                  accept="image/*"
                  className=" absolute z-20 w-full h-[15.4rem] outline-none overflow-hidden"
                  onChange={handleFileChange}
                />
                <div className="flex flex-col space-y-2 items-center">
                  <ImageIcon />
                  <p className="text-[#667085] text-[0.875rem]">
                    Choose Image(s)
                  </p>
                </div>
              </div>
            )}
          </div>
          <p className="font-graphik-regular text-[0.75rem] text-[#667085]">
            Supported: JPG, PNG, JPEG
          </p>
          <div className="flex flex-row justify-start items-center w-full gap-6">
            {businessPhoto.length < 6 && (
              <div className="relative flex h-[6.375rem] w-[6.375rem] md:h-[10.375rem] md:w-[10.375rem] shrink-0 cursor-pointer flex-col items-center justify-center space-y-[0.5rem] rounded-[0.5rem] border-[1px] border-dashed border-[#4B1D8F]">
                <ImageIcon />
                <p className="text-[#667085] text-xs text-center md:text-[0.875rem]">
                  Add more
                </p>
                <input
                  type="file"
                  className="absolute z-20 h-full w-full cursor-pointer border-none focus:outline-none"
                  onChange={handleFileChange}
                />
              </div>
            )}
            <div className="w-full flex flex-row justify-start items-center gap-6 overflow-hidden overflow-x-scroll">
              {businessPhoto.slice(1).map(
                (photo) =>
                  photo && (
                    <div className="relative h-[6.375rem] w-[6.375rem] md:h-[10.375rem] md:w-[10.375rem] shrink-0 overflow-hidden">
                      <button
                        type="button"
                        className="absolute right-1 top-1"
                        onClick={() =>
                          setBusinessPhoto(
                            businessPhoto.filter((item) => item !== photo)
                          )
                        }
                      >
                        <img
                          src={CloseIcon}
                          alt="delete button"
                          className="h-[1.2rem] w-[1.2rem]"
                        />
                      </button>
                      <img
                        src={URL.createObjectURL(photo)}
                        alt="product"
                        className="shrink-0 h-full w-full  object-cover rounded-[0.5rem]"
                      />
                    </div>
                  )
              )}
            </div>
          </div>
          <div className="w-full">
            <input
              onChange={newProductHandler}
              className={`w-full rounded-[0.5rem] border-[1px] ${
                errors.product_name ? "border-red-500" : "border-[#E7E7E7]"
              } px-[1rem] py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0`}
              placeholder="Enter product name"
              id="product_name"
              value={newProduct?.product_name}
              onBlur={onBlurHandler}
            />
            {errors.product_name && (
              <p className="text-red-500 mt-2 text-xs">{errors.product_name}</p>
            )}
          </div>
          <input
            className="w-full rounded-[0.5rem] border-[1px] border-[#E7E7E7] px-[1rem] py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0"
            placeholder="Select category (Optional)"
            id="product_category"
            readOnly
            onClick={() => navigate("/add-photos/category")}
            value={newProduct?.product_category_name}
          />
          <div className="w-full flex flex-row relative">
            <input
              className="w-full rounded-[0.5rem] border-[1px] border-[#E7E7E7] px-[1rem] py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0"
              placeholder="Enter price (Optional)"
              id="price"
              type="number"
              onChange={newProductHandler}
            />
            <p className="absolute right-[1rem] top-[1.2rem] text-[#1A1A1A] text-[0.875rem] font-bold">
              Ksh.
            </p>
          </div>
          <div className="w-full">
            <input
              className={`w-full rounded-[0.5rem] border-[1px] ${
                errors.product_link ? "border-red-500" : "border-[#E7E7E7]"
              } px-[1rem] py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0`}
              placeholder="Paste product link (Optional)"
              id="product_link"
              onChange={newProductHandler}
              value={newProduct.product_link}
              onBlur={onBlurHandler}
            />
            {errors.product_link && (
              <p className="text-red-500 mt-2 text-xs">{errors.product_link}</p>
            )}
          </div>
          <textarea
            className="w-full shrink-0 rounded-[0.5rem] border-[1px] border-[#E7E7E7] px-[1rem] py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0"
            placeholder="Describe your product/service here (Optional)"
            id="description"
            onChange={newProductHandler}
            rows={5}
          />
        </div>
      </div>
      <div className="fixed bottom-0 w-full md:max-w-screen-lg">
        <div className="relative p-4 w-full border-t-[#D1D9DD] bg-white">
          <Transition
            className="absolute -bottom-4 left-0 z-20 w-full flex justify-center items-center"
            show={showError}
            enterFrom=" translate-y-0"
            enterTo=" -translate-y-12"
            leaveFrom=" -translate-y-12"
            leaveTo=" translate-y-0"
          >
            <p className="text-sm text-center py-2 px-4 rounded-xl bg-slate-300/[.8]">
              Please ensure that the form has no errors and the product photo is
              provided.
            </p>
          </Transition>
          <button
            type="button"
            className="w-full h-12 text-white thryve:text-base text-base font-semibold rounded-xl bg-primary-50"
            onClick={uploadProductPhoto}
          >
            {!isLoading ? "Upload" : "Uploading..."}
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default AddPhoto;
